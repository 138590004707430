import { UseMutationOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';
import { FolderLocation } from 'models/types';

export type RenameFolderPostBody = {
    name: string;
    id: number;
    type: string;
};

/**
 * Renames folder.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useRenameFolder = (
    location: FolderLocation,
    mutateOptions?: UseMutationOptions<null, Error, RenameFolderPostBody>
) => {
    const key = MutationKeys.RENAME_FOLDER(location);
    const apiPath =
        location === FolderLocation.AUDIENCE_FOLDER
            ? CuredApiPaths.SEGMENTS_FOLDER_RENAME
            : CuredApiPaths.CONTENT_RENAME;

    return useMutation<RenameFolderPostBody, null>(key, apiPath, mutateOptions);
};

export default useRenameFolder;
