import { UseQueryOptions } from 'react-query';
import { FolderLineage } from 'domains/core/components/folders/types';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import { FolderLocation } from 'models/types';
import QueryKeys from './keys';
type UseGetFolderLineageParams = {
    folderId?: number;
    folderLocation?: FolderLocation;
    reactQueryOptions?: UseQueryOptions<FolderLineage[], Error>;
};

const useGetFolderLineage = ({ folderId, folderLocation, reactQueryOptions }: UseGetFolderLineageParams): any => {
    const isContentFolder = folderLocation === FolderLocation.CONTENT_FOLDER;
    const queryKey = QueryKeys.FOLDER_LINEAGE(folderId, folderLocation);
    const apiPath = `${
        isContentFolder ? CuredApiPaths.CONTENT_FOLDER_LINEAGE : CuredApiPaths.SEGMENT_FOLDER_LINEAGE
    }/${folderId}`;

    return useQuery(queryKey, apiPath, reactQueryOptions);
};

export default useGetFolderLineage;
