import { MenuItem } from '@mui/material';
import { useMemo } from 'react';
import Autocomplete from 'domains/core/components/Autocomplete';
import useAssessments, { Assessment } from 'hooks/queries/useAssessments';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import ButtonAssessmentPreview from './ButtonAssessmentPreview';
import useDebounce from 'hooks/useDebounce';

export type Props = {
    textfieldInput: string;
    value: AutocompleteOption<Assessment>;
    onAssessmentPreview: () => void;
    onChange: (e: any, option: AutocompleteOption<Assessment>) => void;
    onInputChange: (e: any, inputValue: string) => void;
};

const AssessmentDropdown = ({ textfieldInput, value, onAssessmentPreview, onChange, onInputChange }: Props) => {
    const debouncedAssessmentInputValue = useDebounce(textfieldInput, 300);
    const { isSuccess: isAssessmentsSuccess, data: assessments } = useAssessments({
        searchTerm: debouncedAssessmentInputValue,
    });

    const assessmentsOptions: AutocompleteOption<{ id: string; name: string }>[] = useMemo(
        () =>
            isAssessmentsSuccess
                ? assessments?.data.map((assessment: { id: string; name: string }) => ({
                      value: assessment,
                      label: assessment.name,
                  }))
                : [],
        [isAssessmentsSuccess, assessments]
    );

    return (
        <Autocomplete
            data-testid="assessment-dropdown"
            freeSolo={false}
            getOptionLabel={(option) => option.label ?? ''}
            inputValue={textfieldInput ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            label="Select Assessment"
            leadingIcon={value ? <ButtonAssessmentPreview onClick={onAssessmentPreview} /> : null}
            onChange={onChange}
            onInputChange={onInputChange}
            options={assessmentsOptions ?? []}
            placeholder="Select Assessment"
            renderOption={({ className, ...rest }, { index, label, value }) => (
                <MenuItem {...rest} children={label} key={value.id} value={value} />
            )}
            sx={{ width: '300px' }}
            type="string"
            value={value ?? null}
        />
    );
};

export default AssessmentDropdown;
