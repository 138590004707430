import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import SMSSendTestDialog from './SMSSendTestDialog';

type Props = {
    content: string;
    isContentMinLength: boolean;
};

const SMSSendTest = ({ content, isContentMinLength }: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const isButtonDisabled = !isContentMinLength;

    return (
        <>
            <SMSSendTestDialog isOpen={isDialogOpen} message={content} setIsOpen={setIsDialogOpen} />
            <Tooltip
                title={
                    isButtonDisabled
                        ? 'Please provide a content between 3 and 1600 characters before sending a test SMS.'
                        : ''
                }
            >
                <span>
                    <Button disabled={isButtonDisabled} onClick={() => setIsDialogOpen(true)} variant="outlined">
                        Send Test
                    </Button>
                </span>
            </Tooltip>
        </>
    );
};

export default SMSSendTest;
