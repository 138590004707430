import { useHistory } from 'react-router-dom';
import { FolderModalTypes } from 'domains/core/components/folders/types';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown';
import { Segment, SegmentTableItemType } from 'domains/segments/types';
import useSegmentQueryManager from 'hooks/useSegmentQueryManager';
import { Folder } from 'models/types';
import { URLPaths } from 'models/enums';
import { getSegmentCreatePath, hasInvalidRelativeDates } from 'domains/segments/utils';
import { removeTypeFromId } from 'utils/folders';

type Props = {
    handleModalOpen: (item: Segment | Folder, modalType: FolderModalTypes) => void;
    isDownloadEnabled: boolean;
    setAudienceToDownload: (segment: Segment) => void;
    value: any;
};

const useGetMoreOptionsCell = ({ handleModalOpen, isDownloadEnabled, setAudienceToDownload, value }: Props) => {
    const history = useHistory();

    const { changeViewParams, folderId } = useSegmentQueryManager();

    const rowData = {
        ...value.row.original,
        id: removeTypeFromId(value.row.original.id),
    };

    const isDeleteEnabled = rowData.canDelete;

    const deleteOption = {
        name: 'Delete',
        onClick: () => handleModalOpen(rowData, FolderModalTypes.DELETE),
    };

    const segmentMenuItems = [
        {
            name: 'View Contacts',
            onClick: () => {
                history.push(URLPaths.SEGMENTS_CONTACTS, {
                    segmentId: rowData.id,
                    segmentName: rowData.name,
                });
            },
        },
        {
            disabled: hasInvalidRelativeDates(value.row.values),
            name: 'Copy Audience Filters',
            onClick: () => {
                history.push(getSegmentCreatePath(folderId), {
                    segmentDefinition: rowData.segmentDefinition,
                    parentFolderId: folderId,
                });
            },
        },
        {
            name: 'Move',
            onClick: () => handleModalOpen(rowData, FolderModalTypes.MOVE),
        },
        ...(isDownloadEnabled
            ? [
                  {
                      name: 'Download Contacts',
                      onClick: () => setAudienceToDownload(rowData),
                  },
              ]
            : []),
        ...(isDeleteEnabled ? [deleteOption] : []),
    ];

    const folderMenuItems = [
        {
            name: 'Open',
            onClick: () => changeViewParams({ newFolderId: rowData.id }),
        },
        {
            name: 'Rename',
            onClick: () => handleModalOpen(rowData, FolderModalTypes.RENAME),
        },
        ...(isDeleteEnabled ? [deleteOption] : []),
    ];

    const menuItems = rowData.type === SegmentTableItemType.FOLDER ? folderMenuItems : segmentMenuItems;

    return <MoreOptionsDropdown menuItems={menuItems} />;
};

export default useGetMoreOptionsCell;
