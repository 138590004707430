import { Stack } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Folder as FolderIcon } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import DeleteTableItemModal from 'domains/core/components/DeleteTableItemModal';
import FolderBreadcrumb from 'domains/core/components/folders/FolderBreadcrumb';
import FolderEmptyState from 'domains/core/components/folders/FolderEmptyState';
import RenameFolderModal from 'domains/core/components/folders/RenameFolderModal';
import MoveItemModal from 'domains/core/components/folders/MoveItemModal';
import { FolderBreadcrumbData, FolderLineage, FolderModalTypes } from 'domains/core/components/folders/types';
import IconButton from 'domains/core/components/IconButton';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import SegmentNameInput from 'domains/segments/components/SegmentNameInput';
import { RootSegmentFolder, Segment, SegmentTableItemType } from 'domains/segments/types';
import downloadFile from 'helpers/downloadFile/downloadFile';
import useContacts from 'hooks/queries/useContacts';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import useGetFolderLineage from 'hooks/queries/useGetFolderLineage';
import useSegmentQueryManager from 'hooks/useSegmentQueryManager';
import { Folder, FolderLocation } from 'models/types';
import { removeTypeFromId } from 'utils/folders';
import SegmentDetails from './SegmentDetails';
import useGetMoreOptionsCell from './useGetMoreOptionsCell';

const allContactsRowName = 'All Contacts';

export type Props<SegmentTableItemType extends Segment | Folder> = {
    isNestedContent?: boolean;
    segmentsQuery: UseQueryResult<SegmentTableItemType[], Error>;
};

/**
 * A table used for displaying audiences in the audiences page.
 * @returns The React node created by this component.
 */
const SegmentsTable = <SegmentTableItemType extends Segment | Folder>({
    isNestedContent,
    segmentsQuery: { data: queryData, isRefetching, refetch },
}: Props<SegmentTableItemType>) => {
    const { audienceFolders: hasAudienceFoldersFeature } = useFlags();

    const [audienceToDownload, setAudienceToDownload] = useState(null);

    // activeItem can be an Segment or a Folder
    const [activeItem, setActiveItem] = useState(null);
    const [activeFolder, setActiveFolder] = useState(RootSegmentFolder);
    const [activeModal, setActiveModal] = useState('');

    const [downloadErrorMessage, setDownloadErrorMessage] = useState('');
    const [downloadSuccessMessage, setDownloadSuccessMessage] = useState('');

    const { changeViewParams, folderId } = useSegmentQueryManager();
    const shouldShowEmptyTable = !queryData?.length && isNestedContent;

    // TODO: use refetch in rename
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: folderBreadcrumbData, refetch: refetchFolderBreadcrumbData } = useGetFolderLineage({
        folderId,
        folderLocation: FolderLocation.AUDIENCE_FOLDER,
        reactQueryOptions: {
            enabled: isNestedContent,
        },
    });

    useEffect(() => {
        if (folderBreadcrumbData?.length > 0) {
            folderBreadcrumbData.forEach((folder: FolderBreadcrumbData) => {
                if (folder.level === 1) {
                    setActiveFolder({ ...folder, type: SegmentTableItemType.FOLDER });
                }
            });
        } else {
            setActiveFolder(RootSegmentFolder);
        }
    }, [folderBreadcrumbData, setActiveFolder]);

    const folderBreadcrumbDataWithType = folderBreadcrumbData?.map((breadcrumbData: FolderLineage) => ({
        ...breadcrumbData,
        type: SegmentTableItemType.FOLDER,
    }));

    const {
        isLoading: isFeatureSettingsLoading,
        data: featureSettings,
        isSuccess: isFeatureSettingsSuccess,
    } = useFeatureSettings();

    const {
        isLoading: isContactsDownloadLoading,
        isError: isContactsDownloadError,
        isSuccess: isContactsDownloadSuccess,
    } = useContacts({
        downloadCSV: true,
        options: {
            limit: 'all',
            segmentId: removeTypeFromId(audienceToDownload?.id),
        },
        reactQueryOptions: {
            enabled: !!audienceToDownload,
            onError: () => {
                setDownloadErrorMessage(`Problem with downloading "${audienceToDownload?.name}." Try again.`);
            },
            onSuccess: (csvFile) => {
                setDownloadSuccessMessage(`"${audienceToDownload?.name}" successfully downloaded.`);
                downloadFile({
                    file: csvFile.toString(),
                    fileType: 'csv',
                    fileName: audienceToDownload?.name,
                });
            },
        },
    });

    const handleModalOpen = useCallback(
        (item: Segment | Folder | FolderBreadcrumbData, modalType: FolderModalTypes) => {
            setActiveItem(item);
            setActiveModal(modalType);
        },
        [setActiveItem, setActiveModal]
    );

    const getIsRowExpandable = useCallback(
        (datum: SegmentTableItemType) => {
            if (hasAudienceFoldersFeature) {
                return datum.type === SegmentTableItemType.AUDIENCE ? true : false;
            }
            return true;
        },
        [hasAudienceFoldersFeature]
    );

    const data: SegmentTableItemType[] = useMemo(
        () =>
            queryData?.map((datum: SegmentTableItemType) => ({
                ...datum,
                dateCreated: new Date(datum.createdTimestamp),
                dateUpdated: new Date(datum.lastModifiedTimestamp),
                id: hasAudienceFoldersFeature ? `${datum.type}:${datum.id}` : datum.id,
                isExpandable: getIsRowExpandable(datum),
                type: hasAudienceFoldersFeature ? `${datum.type}` : SegmentTableItemType.AUDIENCE,
            })),
        [queryData, getIsRowExpandable, hasAudienceFoldersFeature]
    );

    const renderRowIcon = (value: any) => {
        if (value.row.original.type === SegmentTableItemType.AUDIENCE)
            return (
                <IconButton
                    ariaLabel="show audience details"
                    handleClick={() => {
                        value.row.toggleRowSelected();
                        value.row.getToggleRowExpandedProps().onClick();
                    }}
                    Icon={value.row.isExpanded ? ExpandLessIcon : ExpandMoreIcon}
                    color="primary"
                />
            );
        if (value.row.original.type === SegmentTableItemType.FOLDER)
            return <FolderIcon color="primary" sx={{ width: '40px' }} />;
    };

    const renderRowName = (value: any) => {
        const isNameEditable =
            value.cell.row.original.name !== allContactsRowName &&
            value.row.original.type !== SegmentTableItemType.FOLDER;

        if (isNameEditable) {
            return <SegmentNameInput id={value.cell.row.original.id} name={value.cell.value} />;
        }
        return <>{value.cell.value}</>;
    };

    const columns: ColumnOption[] = useMemo(() => {
        const nameColumn: ColumnOption = {
            Header: 'name',
            accessor: 'name',
            align: 'left',
            sortType: 'string',
            Cell: (value: any) => (
                <Stack direction="row" alignItems="center" columnGap={1}>
                    {renderRowIcon(value)}
                    {renderRowName(value)}
                </Stack>
            ),
        };

        const dateCreatedColumn: ColumnOption = {
            Header: 'date created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };

        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'options',
            align: 'right',
            disableSortBy: true,
            Cell: (value: any) => {
                const isDownloadEnabled = isFeatureSettingsSuccess && featureSettings?.allow_mass_contact_retrieval;
                return useGetMoreOptionsCell({ handleModalOpen, isDownloadEnabled, setAudienceToDownload, value });
            },
        };

        return [nameColumn, dateCreatedColumn, moreOptionsColumn];
    }, [isFeatureSettingsSuccess, featureSettings?.allow_mass_contact_retrieval, handleModalOpen]);

    const stickyRowFilter = useCallback((row: any) => row.original.name === allContactsRowName, []);

    const onRowClick = (row: any) => {
        const id = removeTypeFromId(row.original.id);
        if (row.original.type === SegmentTableItemType.FOLDER) {
            changeViewParams({ newFolderId: id });
        }
    };

    const handleBreadcrumbClick = (newFolderId: number) => changeViewParams({ newFolderId });

    if (isFeatureSettingsLoading) return;

    return (
        <>
            {isContactsDownloadError && <ErrorSnackbar errorMessage={downloadErrorMessage} />}
            {isContactsDownloadLoading && <InfoSnackbar successMessage={`Downloading "${audienceToDownload?.name}"`} />}
            {isContactsDownloadSuccess && <SuccessSnackbar successMessage={downloadSuccessMessage} />}
            <DeleteTableItemModal
                activeItem={activeItem}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === FolderModalTypes.DELETE}
                refetchTableData={refetch}
                resetActiveItem={() => setActiveItem(null)}
            />
            <RenameFolderModal
                activeFolder={activeItem}
                folderLocation={FolderLocation.AUDIENCE_FOLDER}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === FolderModalTypes.RENAME}
                refetch={isNestedContent ? refetchFolderBreadcrumbData : refetch}
                resetActiveFolder={() => setActiveItem(null)}
            />
            <MoveItemModal
                activeItem={activeItem}
                activeFolder={activeFolder}
                folderLocation={FolderLocation.AUDIENCE_FOLDER}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === FolderModalTypes.MOVE}
                refetch={refetch}
                resetActiveItem={() => setActiveItem(null)}
            />
            {hasAudienceFoldersFeature && (
                <FolderBreadcrumb
                    data={folderBreadcrumbDataWithType}
                    handleBreadcrumbClick={handleBreadcrumbClick}
                    handleModalOpen={handleModalOpen}
                    showDeleteFolderOption={shouldShowEmptyTable}
                />
            )}
            <Table
                columns={columns}
                data={data}
                defaultSortingRules={[{ id: 'dateCreated', desc: true }]}
                isRefetching={isRefetching}
                stickyRow
                stickyRowFilter={stickyRowFilter}
                renderRowSubComponent={(row: any) => {
                    if (row.original.isExpandable) {
                        return <SegmentDetails segment={row.original} />;
                    }
                    return null;
                }}
                refetch={refetch}
                {...(hasAudienceFoldersFeature && { onRowClick })}
            />
            {shouldShowEmptyTable && <FolderEmptyState location={FolderLocation.AUDIENCE_FOLDER} />}
        </>
    );
};

export default SegmentsTable;
