import { Box, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import {
    setHasRecommendedTime,
    setScheduledTimestamp,
} from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { channelIcons } from 'domains/campaigns/pages/CurationsPage/utils';
import {
    getIsRecurrenceContinuous,
    getIsRecurrenceEnabled,
    getSendDatetimeError,
    maxDate,
    maxTime,
    minDate,
    minTime,
} from 'domains/campaigns/utils';
import { CampaignChannelLabels, CampaignChannels } from 'domains/campaigns/types';
import Alert from 'domains/core/components/Alert';
import DatePicker from 'domains/core/components/DatePicker';
import FormLayout from 'domains/core/components/FormLayout';
import TimePicker from 'domains/core/components/TimePicker';
import theme from 'theme';
import { getTimezone } from 'utils';
import EndDateTimePicker from './EndDateTimePicker';
import RecommendedTimeSwitch from './RecommendedTimeSwitch';
import RecurrenceDropdown from './RecurrenceDropdown';
import { getRecommendedTime } from './utils/getRecommendedTime';

const getLabelPrefix = (isRecurrenceContinuous: boolean): string => (isRecurrenceContinuous ? 'Start' : 'Send');
const getDateLabel = (isRecurrenceContinuous: boolean): string => `${getLabelPrefix(isRecurrenceContinuous)} Date`;
const getTimeLabel = (isRecurrenceContinuous: boolean): string =>
    `${getLabelPrefix(isRecurrenceContinuous)} Time (${getTimezone()})`;

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignScheduleStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const { flexibleCurationsFullFeatures: hasFlexibleCurationsFullFeatures } = useFlags();

    const currentSend = campaignState.sends[activeSendIndex];
    const { channel, scheduledTimestamp } = currentSend;
    const isDirectMailSend = channel === CampaignChannels.DIRECT_MAIL;
    const isEmailSend = channel === CampaignChannels.EMAIL;
    const isSMSSend = channel === CampaignChannels.SMS;
    const sendCanRecur = isEmailSend || isSMSSend;
    const scheduledEndTimestamp = sendCanRecur ? currentSend.scheduledEndTimestamp : null;
    const recurrenceFrequency = sendCanRecur ? currentSend.recurrenceFrequency : null;
    const hasRecommendedTime = isEmailSend ? currentSend.hasRecommendedTime : false;

    const sendDateTimeLuxon = scheduledTimestamp ? DateTime.fromISO(scheduledTimestamp) : DateTime.now();
    const endDatetimeLuxon = scheduledEndTimestamp ? DateTime.fromISO(scheduledEndTimestamp) : null;

    const [sendDate, setSendDate] = useState<DateTime>(scheduledTimestamp ? sendDateTimeLuxon : null);
    const [sendTime, setSendTime] = useState<DateTime>(scheduledTimestamp ? sendDateTimeLuxon : null);
    const [hasEndDate, setHasEndDate] = useState(scheduledEndTimestamp ? true : false);
    const [endDate, setEndDate] = useState<DateTime>(endDatetimeLuxon);

    const [isRecommendedTimeSwitchTouched, setIsRecommendedTimeSwitchTouched] = useState(false);

    const isRecurrenceEnabled = getIsRecurrenceEnabled(recurrenceFrequency);
    const sendDateErrorMessage = sendDate && getSendDatetimeError(sendDate, true);
    const sendTimeErrorMessage = sendTime && getSendDatetimeError(sendTime, false);

    useEffect(() => {
        if (hasFlexibleCurationsFullFeatures && isEmailSend) {
            setNextStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        } else {
            setNextStep(CustomCampaignSteps.PUBLISH_CAMPAIGN);
        }
        // The dependency array is empty because this useEffect should run exactly once after the initial rendering of the component.
        // The hook is not dependent on any props or state variables to re-run. This is intended to perform the initial setup based on
        // the `activeSendIndex` value when the component mounts without reacting to any further updates or re-renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsContinueEnabled(!!sendDate && !!sendTime && !sendDateErrorMessage && !sendTimeErrorMessage);
    }, [sendDate, sendDateErrorMessage, sendTime, sendTimeErrorMessage, setIsContinueEnabled]);

    const handleChangeSendDateTime = (dateTime: DateTime, isDate?: boolean) => {
        if (isEmailSend) {
            setIsRecommendedTimeSwitchTouched(true);
        }

        isDate ? setSendDate(dateTime) : setSendTime(dateTime);

        if (!dateTime?.isValid && isEmailSend) {
            dispatchCampaignAction(setHasRecommendedTime(false));
            return;
        }

        // Updating sendDateTimeLuxon regardless of whether it's date or time
        const updatedSendDateTime = sendDateTimeLuxon.set({
            year: isDate ? dateTime.year : sendDateTimeLuxon.year,
            month: isDate ? dateTime.month : sendDateTimeLuxon.month,
            day: isDate ? dateTime.day : sendDateTimeLuxon.day,
            hour: !isDate ? dateTime.hour : sendDateTimeLuxon.hour,
            minute: !isDate ? dateTime.minute : sendDateTimeLuxon.minute,
        });

        // Recommended time should be considered only when changing date and having recommended time enabled
        const shouldHaveRecommendedTime = isDate && hasRecommendedTime;

        // Get recommended time if necessary
        const finalDateTime = shouldHaveRecommendedTime
            ? getRecommendedTime(updatedSendDateTime, recurrenceFrequency)
            : updatedSendDateTime;

        dispatchCampaignAction(setScheduledTimestamp(finalDateTime.toISO(), activeSendIndex));
        if (isEmailSend) {
            dispatchCampaignAction(setHasRecommendedTime(shouldHaveRecommendedTime));
        }

        if (!getSendDatetimeError(finalDateTime, isDate)) {
            setSendDate(finalDateTime);
            setSendTime(finalDateTime);
        }
    };

    const handleRecommendedTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsRecommendedTimeSwitchTouched(true);

        if (e.target.checked) {
            const newDatetime = getRecommendedTime(DateTime.fromISO(scheduledTimestamp), recurrenceFrequency);

            setSendTime(newDatetime);
            dispatchCampaignAction(setScheduledTimestamp(newDatetime.toISO(), activeSendIndex));
            dispatchCampaignAction(setHasRecommendedTime(true));
        } else {
            dispatchCampaignAction(setHasRecommendedTime(false));
        }
    };

    const shouldShowSendTimeAlert = isDirectMailSend && scheduledTimestamp;
    const shouldShowRecommendedTimeSwitch = isEmailSend;
    const shouldShowReccurenceDropdown = sendCanRecur && sendDate;
    const shouldShowEndDateTimePicker = sendDate && isRecurrenceEnabled;
    const isRecurrenceContinuous = getIsRecurrenceContinuous(recurrenceFrequency);

    const StepIcon = channelIcons[channel];

    const getSlotProps = (isTime?: boolean) => ({
        textField: {
            error: isTime ? !!sendTimeErrorMessage : !!sendDateErrorMessage,
            helperText: isTime ? sendTimeErrorMessage : sendDateErrorMessage,
            required: true,
            sx: { width: '100%' },
        },
    });

    return (
        <FormLayout
            header="schedule content"
            stepName={CampaignChannelLabels[channel]}
            icon={<StepIcon />}
            guidingText="Set the date and time of your send. Have your audience in mind to optimize your send time."
        >
            <Stack rowGap={4}>
                <DatePicker
                    data-testid="date-picker"
                    label={getDateLabel(isRecurrenceContinuous)}
                    value={sendDate}
                    minDate={minDate()}
                    maxDate={maxDate()}
                    onChange={(dateTime) => handleChangeSendDateTime(dateTime, true)}
                    slotProps={{
                        ...getSlotProps(),
                        popper: {
                            style: { paddingRight: theme.spacing(3.25) },
                        },
                    }}
                />
                <TimePicker
                    label={getTimeLabel(isRecurrenceContinuous)}
                    value={sendTime}
                    minTime={minTime(scheduledTimestamp)}
                    maxTime={maxTime(scheduledTimestamp)}
                    onChange={(dateTime) => handleChangeSendDateTime(dateTime)}
                    slotProps={getSlotProps(true)}
                    sx={{ pb: 2 }}
                />
                {shouldShowSendTimeAlert && (
                    <Alert
                        elevation={0}
                        severity="info"
                        variant="outlined"
                        style={{ borderColor: theme.palette.info.main, borderWidth: '2px' }}
                    >
                        Files will start printing the business day after the schedule date
                    </Alert>
                )}
                {shouldShowRecommendedTimeSwitch && (
                    <Box my={-1} pl={1}>
                        <RecommendedTimeSwitch
                            isChecked={hasRecommendedTime}
                            isDisabled={!!sendDateErrorMessage}
                            isTouched={isRecommendedTimeSwitchTouched}
                            recurrenceFrequency={recurrenceFrequency}
                            sendDate={sendDate}
                            onChange={handleRecommendedTimeChange}
                        />
                    </Box>
                )}
                {shouldShowReccurenceDropdown && (
                    <RecurrenceDropdown
                        currentSend={currentSend}
                        dispatchCampaignAction={dispatchCampaignAction}
                        sendDate={sendDate}
                        setEndDate={setEndDate}
                        setHasEndDate={setHasEndDate}
                        setIsRecommendedTimeSwitchTouched={setIsRecommendedTimeSwitchTouched}
                        setSendTime={setSendTime}
                    />
                )}
                {shouldShowEndDateTimePicker && (
                    <EndDateTimePicker
                        dispatchCampaignAction={dispatchCampaignAction}
                        endDate={endDate}
                        hasEndDate={hasEndDate}
                        sendDate={sendDate}
                        setEndDate={setEndDate}
                        setHasEndDate={setHasEndDate}
                        setIsContinueEnabled={setIsContinueEnabled}
                        setIsRecommendedTimeSwitchTouched={setIsRecommendedTimeSwitchTouched}
                    />
                )}
            </Stack>
        </FormLayout>
    );
};

export default CustomCampaignScheduleStep;
