import { useState, useEffect } from 'react';

// Custom hook for debouncing
const useDebounce = (value: any, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set a timeout to update the debounced value
        const handler = setTimeout(() => setDebouncedValue(value), delay);

        // Cleanup function to clear the timeout if value changes before delay
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;
