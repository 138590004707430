import { NavigateNext } from '@mui/icons-material';
import { ButtonProps, Stack } from '@mui/material';
import Button from 'domains/core/components/Button';
import DropdownButton from 'domains/core/components/DropdownButton';
import theme from 'theme';
import { FolderBreadcrumbData, FolderModalTypes } from '../types';

const breadcrumbStyles = {
    height: '32px',
    marginRight: 1,
    padding: 1,
};

type Props = {
    data?: FolderBreadcrumbData[];
    handleBreadcrumbClick: (id: number) => void;
    handleModalOpen: (activeFolder: FolderBreadcrumbData, modalType: FolderModalTypes) => void;
    showDeleteFolderOption: Boolean;
};

const FolderBreadcrumb = ({ data, handleBreadcrumbClick, handleModalOpen, showDeleteFolderOption }: Props) => {
    if (!data?.length) return;

    const sharedBreadcrumbProps = (isDropdownButton?: boolean): ButtonProps => ({
        size: 'large',
        variant: 'text',
        sx: {
            ...breadcrumbStyles,
            color: isDropdownButton ? theme.palette.action.active : 'inherit',
        },
    });

    const renderBreadcrumbs = () =>
        data.map((folder: FolderBreadcrumbData) => {
            const isLastChild = folder.level === 1;
            if (isLastChild) {
                const renameOption = {
                    label: 'Rename',
                    onClick: () => handleModalOpen(folder, FolderModalTypes.RENAME),
                };
                const deleteOption = {
                    label: 'Delete',
                    onClick: () => handleModalOpen(folder, FolderModalTypes.DELETE),
                };
                const options = showDeleteFolderOption ? [renameOption, deleteOption] : [renameOption];

                return (
                    <DropdownButton
                        key={`${folder.name}${folder.id}`}
                        label={folder.name}
                        options={options}
                        {...sharedBreadcrumbProps(true)}
                    />
                );
            }
            return (
                <Button
                    key={`${folder.name}${folder.id}`}
                    onClick={() => handleBreadcrumbClick(folder.id)}
                    endIcon={<NavigateNext />}
                    {...sharedBreadcrumbProps()}
                >
                    {folder.name}
                </Button>
            );
        });

    return (
        <Stack direction="row">
            <Button
                key="navigate-to-main-table"
                onClick={() => handleBreadcrumbClick(-1)}
                endIcon={<NavigateNext />}
                {...sharedBreadcrumbProps()}
            >
                All
            </Button>
            {renderBreadcrumbs()}
        </Stack>
    );
};

export default FolderBreadcrumb;
