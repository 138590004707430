import { CuredApiPaths } from 'models/enums';
import { UseQueryOptions } from 'react-query';
import useQuery from '../useQuery';
import QueryKeys from './keys';

export type AssessmentConsent = {
    message: string;
    verificationType: 'YEAR' | 'DOB';
    images: object;
};

const useCampaigns = (reactQueryOptions?: UseQueryOptions<AssessmentConsent, Error>) =>
    useQuery(QueryKeys.ASSESSMENT_CONSENT, CuredApiPaths.ASSESSMENT_CONSENT, reactQueryOptions);

export default useCampaigns;
