import { ContentModalContentType, ContentTypeForComparison } from 'domains/content/types';
import { Segment, SegmentTableItemType } from 'domains/segments/types';
import { Folder } from 'models/types';
import useDeleteContentFolder from './mutations/useDeleteContentFolder';
import useDeleteLandingPage from './mutations/useDeleteLandingPage';
import useDeleteTemplate from './mutations/useDeleteTemplate';
import useContentQueryManager from './useContentQueryParams';
import useDeleteSegment from './mutations/useDeleteSegment';
import useDeleteSegmentFolder from './mutations/useDeleteSegmentFolder';
import useSegmentQueryManager from './useSegmentQueryManager';

type Props = {
    activeItem: ContentModalContentType | Segment | Folder;
    refetchTableData: () => void;
    resetActiveItem: () => void;
    setErrorMessage: (error: string) => void;
    setSuccessMessage: (message: string) => void;
};

const useDeleteTableItem = ({
    activeItem,
    refetchTableData,
    resetActiveItem,
    setErrorMessage,
    setSuccessMessage,
}: Props) => {
    const { changeViewParams: changeContentTableViewParams, folderId: contentTableFolderId } = useContentQueryManager();

    const { changeViewParams: changeSegmentTableViewParams } = useSegmentQueryManager();

    const onError = (error: Error) => {
        setErrorMessage(error.message);
        resetActiveItem();
    };

    const onSuccess = () => {
        setSuccessMessage(`"${activeItem?.name}" successfully deleted.`);
        if (activeItem.type === ContentTypeForComparison.FOLDER && contentTableFolderId !== -1) {
            changeContentTableViewParams({ newFolderId: -1 });
        }
        if (activeItem.type === SegmentTableItemType.FOLDER) {
            changeSegmentTableViewParams({ newFolderId: -1 });
        }
        refetchTableData();
        resetActiveItem();
    };

    const itemToDeleteId = activeItem ? `${activeItem?.id}` : null;

    // Content table methods
    const {
        isLoading: isDeleteLandingPageLoading,
        mutate: deleteLandingPage,
        isSuccess: isDeleteLandingPageSuccess,
    } = useDeleteLandingPage(itemToDeleteId, contentTableFolderId, { onError, onSuccess });

    const {
        isLoading: isDeleteTemplateLoading,
        mutate: deleteTemplate,
        isSuccess: isDeleteTemplateSuccess,
    } = useDeleteTemplate(itemToDeleteId, contentTableFolderId, { onError, onSuccess });

    const {
        isLoading: isDeleteContentFolderLoading,
        mutate: deleteContentFolder,
        isSuccess: isDeleteContentFolderSuccess,
    } = useDeleteContentFolder(itemToDeleteId, { onError, onSuccess });

    // Segment table methods
    const {
        isLoading: isDeleteSegmentLoading,
        mutate: deleteSegment,
        isSuccess: isDeleteSegmentSuccess,
    } = useDeleteSegment(`${activeItem?.id}`, { onError, onSuccess });

    const {
        isLoading: isDeleteSegmentFolderLoading,
        mutate: deleteSegmentFolder,
        isSuccess: isDeleteSegmentFolderSuccess,
    } = useDeleteSegmentFolder(itemToDeleteId, { onError, onSuccess });

    const getDeleteItemFunction = () => {
        switch (activeItem?.type) {
            case ContentTypeForComparison.EMAIL:
                return deleteTemplate;
            case ContentTypeForComparison.LANDING_PAGE:
                return deleteLandingPage;
            case ContentTypeForComparison.FOLDER:
                return deleteContentFolder;
            case SegmentTableItemType.AUDIENCE:
                return deleteSegment;
            case SegmentTableItemType.FOLDER:
                return deleteSegmentFolder;
            default:
                return () => {};
        }
    };

    const isLoading =
        isDeleteLandingPageLoading ||
        isDeleteTemplateLoading ||
        isDeleteContentFolderLoading ||
        isDeleteSegmentLoading ||
        isDeleteSegmentFolderLoading;

    const isSuccess =
        isDeleteLandingPageSuccess ||
        isDeleteTemplateSuccess ||
        isDeleteContentFolderSuccess ||
        isDeleteSegmentSuccess ||
        isDeleteSegmentFolderSuccess;

    return { getDeleteItemFunction, isLoading, isSuccess };
};

export default useDeleteTableItem;
