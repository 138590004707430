import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { URLPaths } from 'models/enums';

/**
 * A hook to get the type, folderId and the function to update both for the content page.
 * */
const useSegmentQueryManager = () => {
    const history = useHistory();
    let { folderId } = useParams<{ folderId: string | undefined }>();

    const numericFolderId = folderId === undefined ? -1 : Number(folderId);

    const basePath = `${URLPaths.SEGMENTS}`;

    const changeViewParams = useCallback(
        ({ newFolderId }: { newFolderId?: number } = {}) => {
            const getFolderPath = (newFolderId: number) => {
                if (newFolderId) {
                    return newFolderId === -1 ? '' : `/${newFolderId}`;
                }
                return folderId ? `/${folderId}` : '';
            };

            const folderPath = getFolderPath(newFolderId);

            const path = `${basePath}${folderPath}`;

            history.push(path);
        },
        [history, folderId, basePath]
    );

    return { folderId: numericFolderId, changeViewParams };
};

export default useSegmentQueryManager;
