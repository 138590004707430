import { FolderOpenOutlined, NavigateNext } from '@mui/icons-material';
import { SvgIconProps, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { FolderBreadcrumbData } from 'domains/core/components/folders/types';
import IconButton from 'domains/core/components/IconButton';
import theme from 'theme';
import { Folder } from 'models/types';
import { channelIcons } from 'domains/campaigns/pages/CurationsPage/utils';

const sharedRowStyles = {
    alignItems: 'center',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '1rem',
    height: '48px',
    justifyContent: 'flex-start',
    padding: '0 1rem',
};

const clickableRowStyles: CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    height: '100%',
};

const currentLocationRowStyles = {
    fontWeight: 'bold',
    pointerEvents: 'none',
};

const sharedInteractiveStyles = {
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.action.hover,
    },
};

const selectedRowStyles = {
    background: theme.palette.action.hover,
};

const variant = 'body2';

type Props = {
    activeFolder?: FolderBreadcrumbData;
    DomainIcon?: React.ComponentType<SvgIconProps>;
    isRowSelected?: boolean;
    isPreview?: boolean;
    rootFolder?: Folder;
    row?: Folder;
    setFutureLocation?: (location?: Folder) => void;
    setPreviewedLocation?: (location?: Folder) => void;
};

const ModalItem = ({
    activeFolder,
    DomainIcon,
    isPreview,
    isRowSelected,
    rootFolder,
    row,
    setFutureLocation,
    setPreviewedLocation,
}: Props) => {
    const getIsRowInFolder = (id: number) => id === activeFolder.id;

    const handleRowClick = (isRowInFolder: boolean) => {
        if (isRowInFolder) return;
        if (isRowSelected) {
            setFutureLocation(null);
        } else {
            const futureLocation = row ?? rootFolder;
            setFutureLocation(futureLocation);
        }
    };

    if (!row) {
        const isRowInRootFolder = getIsRowInFolder(rootFolder.id);

        return (
            <Typography
                onClick={() => handleRowClick(isRowInRootFolder)}
                sx={{
                    ...sharedRowStyles,
                    ...(isRowInRootFolder ? currentLocationRowStyles : sharedInteractiveStyles),
                    ...(isRowSelected && selectedRowStyles),
                }}
                variant={variant}
            >
                <DomainIcon sx={{ marginRight: 1 }} />
                {rootFolder.name}
            </Typography>
        );
    }

    const RowIcon = channelIcons[row.type] || FolderOpenOutlined;

    if (isPreview) {
        return (
            <Typography variant={variant} sx={sharedRowStyles}>
                <RowIcon sx={{ marginRight: 1 }} />
                {row.name}
            </Typography>
        );
    }

    const isRowInFolder = getIsRowInFolder(row.id);

    const handlePreviewClick = () => {
        setPreviewedLocation(row);
        if (!isRowInFolder) setFutureLocation(row);
    };

    return (
        <Typography
            sx={{
                ...sharedRowStyles,
                ...(!isRowInFolder && sharedInteractiveStyles),
                ...(isRowInFolder && {
                    fontWeight: 'bold',
                }),
                ...(isRowSelected && selectedRowStyles),
                justifyContent: 'space-between',
            }}
            variant={variant}
        >
            <span
                data-testid={`modal-item-${row.name}`}
                style={clickableRowStyles}
                onClick={() => handleRowClick(isRowInFolder)}
            >
                <RowIcon sx={{ marginRight: 1 }} />
                {row.name}
            </span>
            <IconButton title="preview content" handleClick={handlePreviewClick} Icon={NavigateNext} />
        </Typography>
    );
};

export default ModalItem;
