import { CuredApiPaths } from 'models/enums';
import { UseMutationOptions } from 'react-query';
import MutationKeys from './keys';
import useMutation from 'hooks/useMutation';
import { FolderLocation } from 'models/types';

export type MoveItemPostBody = {
    id: number;
    type: string;
    currFolderId: number;
    targetFolderId: number;
};

export type MoveItemResponse = {
    targetFolderId: number | null;
};

/**
 * Move content/segment/etc. from one folder to another.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useMoveItem = (
    location: FolderLocation,
    mutateOptions?: UseMutationOptions<MoveItemResponse, Error, MoveItemPostBody>
) => {
    const path: Record<FolderLocation, CuredApiPaths> = {
        [FolderLocation.CONTENT_FOLDER]: CuredApiPaths.CONTENT_MOVE,
        [FolderLocation.AUDIENCE_FOLDER]: CuredApiPaths.SEGMENT_MOVE,
    };

    return useMutation<MoveItemPostBody, MoveItemResponse>(
        MutationKeys.MOVE_FOLDER(location),
        path[location],
        mutateOptions
    );
};

export default useMoveItem;
