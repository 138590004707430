// workaround for "error TS2590: Expression produces a union type that is too complex to represent." when running test:coverage
// @ts-nocheck
import { tableSortLabelClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { themeColors } from 'colors';
import { fontSizeH6, fontSizeB1, fontSizeOverline, fontSansSerif, fontSerif } from 'typography';

const inputLabel = {
    backgroundColor: '#fff',
    color: themeColors.text.primary,
    fontFamily: fontSansSerif,
    fontSize: fontSizeOverline,
    fontWeight: 400,
    letterSpacing: '.15px',
    lineHeight: 1.5,
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    textTransform: 'capitalize',
};

const inputText = {
    color: themeColors.text.primary,
    fontFamily: fontSansSerif,
    fontSize: fontSizeB1,
    fontWeight: 400,
    letterSpacing: '.15px',
    lineHeight: 1.5,
};

const helperText = {
    color: themeColors.text.primary,
    fontFamily: fontSansSerif,
    fontSize: fontSizeOverline,
    fontWeight: 400,
    letterSpacing: '.4px',
    lineHeight: 2,
};

let theme = createTheme({
    palette: {
        ...themeColors,
    },
    typography: {
        allVariants: {},
        fontFamily: fontSansSerif,
        // default variants
        h2: {
            color: themeColors.text.primary,
            fontWeight: 400,
            letterSpacing: '-.5px',
            lineHeight: 1.066,
        },
        h3: {
            color: themeColors.text.primary,
            fontFamily: fontSerif,
            fontWeight: 600,
            lineHeight: 1,
        },
        h4: {
            color: themeColors.text.primary,
            fontSize: '2.5rem', // 40px,
            fontWeight: 500,
            letterSpacing: 'normal',
            lineHeight: 1,
        },
        h6: {
            color: themeColors.text.primary,
            fontSize: '1.5rem', // 24px
            fontWeight: 400,
            letterSpacing: '.15px',
            lineHeight: 1.333,
        },
        body1: {
            color: themeColors.text.primary,
            fontSize: fontSizeH6,
            letterSpacing: '.15px',
            lineHeight: 1.25,
        },
        body2: {
            color: themeColors.text.primary,
            fontSize: fontSizeB1,
            letterSpacing: '.15px',
            lineHeight: 1.5,
        },
        button: {},
        caption: {
            display: 'block',
            fontSize: '1rem',
            letterSpacing: '2px',
            lineHeight: 1.5,
            fontWeight: '500',
        },
        overline: {
            color: themeColors.text.primary,
            display: 'block',
            fontFamily: fontSansSerif,
            fontWeight: 500,
            letterSpacing: '.5px',
            lineHeight: fontSizeB1,
            textTransform: 'none',
        },
        // custom variants
        buttonLarge: {
            color: themeColors.text.primary,
            fontFamily: fontSansSerif,
            fontSize: '1.25', // 20px
            fontWeight: 500,
            letterSpacing: '.5px',
            lineHeight: 1.5, // 24 px
        },
        buttonMedium: {
            color: themeColors.text.primary,
            fontFamily: fontSansSerif,
            fontSize: '1rem', // 16 px
            fontWeight: 500,
            letterSpacing: '.5px',
            lineHeight: 1.5, // 24 px
        },
        buttonSmall: {
            color: themeColors.text.primary,
            fontFamily: fontSansSerif,
            fontSize: '0.875rem', // 14px
            fontWeight: 500,
            letterSpacing: '.5px',
            lineHeight: 1.5, // 24 px
        },
        avatarInitials: {
            color: themeColors.text.primary,
            fontFamily: fontSansSerif,
            fontSize: fontSizeH6,
            fontWeight: 400,
            letterSpacing: '.14px',
            lineHeight: 1,
        },
        tooltip: {
            display: 'block',
            fontFamily: fontSansSerif,
            fontSize: fontSizeB1,
            fontWeight: 500,
            lineHeight: 1.5,
        },
        inputLabel,
        inputText,
        helperText,
    },
});

theme = createTheme(theme, {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: themeColors.primary.contrast,
                    zIndex: 0,
                    boxShadow: 'none',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-root .MuiAutocomplete-input': { padding: '0px' },
                    '.MuiOutlinedInput-root ': { padding: '12px 32px 12px 16px' },
                },
                inputRoot: {
                    backgroundColor: themeColors.primary.contrast,
                },
                listbox: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'medium',
            },
            styleOverrides: {
                root: {
                    minWidth: '48px',
                    borderRadius: '6px',
                    height: 'auto',
                    padding: '0rem 1.25rem',
                    textTransform: 'none',
                },
                contained: {
                    endIcon: {
                        paddingTop: '0',
                        marginRight: '0',
                    },
                    padding: '0rem 1.5rem',
                    '&:disabled': {
                        backgroundColor: themeColors.action.disabled,
                        opacity: 0.5,
                        color: themeColors.text.disabled,
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: themeColors.primary.containedHoverBackground,
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: themeColors.secondary.containedHoverBackground,
                    },
                },
                containedSizeSmall: {
                    fontSize: fontSizeB1,
                    letterSpacing: '.4px',
                    padding: '.625rem 1.25rem',
                },
                outlined: {
                    backgroundColor: themeColors.primary.contrast,
                    borderColor: themeColors.primary.main,
                    borderWidth: '0.125rem',
                    boxShadow: `1px 1px 0px ${themeColors.primary.main}`,
                    '&:hover': {
                        backgroundColor: themeColors.action.hover,
                        borderWidth: '0.125rem',
                    },
                    '&.Mui-disabled': {
                        borderColor: themeColors.action.disabled,
                        borderWidth: '0.125rem',
                        boxShadow: `1px 1px 0px ${themeColors.action.disabled}`,
                    },
                },
                outlinedSecondary: {
                    borderColor: themeColors.secondary.main,
                    '&:hover': {
                        backgroundColor: themeColors.secondary.outlinedHoverBackground,
                    },
                },
                outlinedSizeSmall: {
                    fontSize: fontSizeB1,
                    letterSpacing: '.4px',
                    padding: '.5rem 1.25rem',
                },
                textPrimary: {
                    '&:hover': {
                        backgroundColor: themeColors.primary.outlinedHoverBackground,
                    },
                },
                textSecondary: {
                    '&:hover': {
                        backgroundColor: themeColors.secondary.outlinedHoverBackground,
                    },
                },
                sizeSmall: {
                    height: 32,
                },
                sizeMedium: {
                    height: 48,
                },
                sizeLarge: {
                    height: 56,
                },
                iconSizeSmall: {
                    '& :first-of-type': {
                        width: 18,
                        height: 18,
                    },
                },
                iconSizeMedium: {
                    '& :first-of-type': {
                        width: 20,
                        height: 20,
                    },
                },
                iconSizeLarge: {
                    '& :first-of-type': {
                        width: 24,
                        height: 24,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderColor: themeColors.primary.main,
                    borderWidth: 2,
                    paddingBottom: 1,
                },
            },
        },
        MuiDateCalendar: { styleOverrides: { root: { height: 358 } } },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.MuiBackdrop-root': {
                        backgroundColor: '#95a6a733',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '1rem 2rem 2rem 2rem',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '2rem 2rem 1rem 2rem',
                    ...theme.typography.h6,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                    padding: '0 2rem 0 2rem',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: themeColors.primary.main,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    ...helperText,
                    alignSelf: 'flex-end',
                    height: 0,
                    marginTop: 8,
                    marginLeft: 16,
                    marginRight: 16,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                    padding: '.5rem .5rem',
                    color: themeColors.text.primary,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minHeight: '1.5rem',
                    lineHeight: '1.5rem',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    ...inputLabel,
                    borderRadius: '6px',
                },
                outlined: {
                    backgroundColor: themeColors.primary.contrast,
                    fontSize: fontSizeB1,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
                dense: {
                    paddingTop: '.75rem',
                    paddingBottom: '.75rem',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: themeColors.text.primary,
                    fontSize: '1.5rem',
                    minWidth: 'max-content',
                    paddingLeft: '.25rem',
                    paddingRight: '1rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: theme.typography.body1,
                    display: 'block',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    minHeight: 0,
                    padding: '1rem 1rem',
                    '&:hover': {
                        backgroundColor: themeColors.action.hover,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: fontSizeB1,
                    backgroundColor: themeColors.primary.contrast,
                    borderRadius: '6px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '2px solid #123133',
                        boxShadow: '1px 1px 0px #123133',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '2px solid #00845B',
                        boxShadow: '1px 1px 0px #00845B',
                    },
                    '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                        border: '2px solid #00845B',
                        boxShadow: '1px 1px 0px #00845B',
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        border: '2px solid red',
                        boxShadow: '1px 1px 0px red',
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: themeColors.action.disabled,
                        borderStyle: 'dashed',
                        boxShadow: 'none',
                    },
                    padding: '12px 16px',
                },
                input: {
                    height: '1.5rem',
                    padding: 0,
                },
                multiline: {
                    minHeight: '3rem',
                    padding: '1rem 1rem',
                },
                sizeSmall: {
                    padding: '.5rem .75rem',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                button: {
                    height: 36,
                    padding: '6px',
                    width: 36,
                    '& .MuiSvgIcon-root': {
                        fontSize: 24,
                    },
                },
                root: {
                    marginVertical: 10,
                    '& .MuiTypography-root': {
                        ...theme.typography.body1,
                    },
                },
                spacer: {
                    width: theme.spacing(4),
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    fontSize: theme.typography.body1.fontSize,
                },

                root: {
                    marginBottom: 18,
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                paper: {
                    borderRadius: '6px',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    ...inputText,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.MuiInputBase-inputSizeSmall': {
                        paddingTop: '.5rem',
                        paddingBottom: '.5rem',
                    },
                    paddingLeft: '1rem',
                },
                outlined: {
                    backgroundColor: themeColors.primary.contrast,
                    borderColor: themeColors.primary.main,
                    borderRadius: '6px',
                    borderStyle: 'solid',
                    borderWidth: '.125rem',
                    boxShadow: `1px 1px 0px ${themeColors.primary.main}`,
                },
                icon: {
                    color: themeColors.primary.main,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: theme.typography.h4.fontSize,
                },
                colorAction: {
                    color: themeColors.action.active,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: fontSizeH6,
                    padding: '1rem 1.5rem',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 .5rem',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    ...theme.typography.body1,
                    borderBottom: 'none',
                    height: '3rem',
                    maxWidth: '24ch',
                    overflow: 'hidden',
                    padding: '.25rem .75rem',
                    whiteSpace: 'nowrap',
                },
                head: {
                    backgroundColor: themeColors.primary.contrast,
                    whiteSpace: 'nowrap',
                },
                body: {
                    '&:first-of-type': {
                        borderTopLeftRadius: '.25rem',
                        borderBottomLeftRadius: '.25rem',
                        borderLeftColor: themeColors.secondary.main,
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '.25rem',
                    },
                    '&:last-child': {
                        borderTopRightRadius: '.25rem',
                        borderBottomRightRadius: '.25rem',
                    },
                },
                stickyHeader: {
                    backgroundColor: themeColors.primary.contrast,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: themeColors.other.tableBackground,
                    '&:hover': {
                        backgroundColor: themeColors.other.tableHover,
                    },
                    '&.Mui-selected': {
                        backgroundColor: themeColors.action.hover,
                        td: {
                            '&:first-of-type': {
                                borderLeftColor: themeColors.action.active,
                            },
                        },
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    fill: '#000000',
                    opacity: 0.375,
                },
                root: {
                    [`&.${tableSortLabelClasses.root}:hover`]: {
                        '& svg': {
                            fill: themeColors.action.active,
                            opacity: 0.5,
                        },
                    },
                    [`&.${tableSortLabelClasses.active}`]: {
                        '& svg': {
                            fill: themeColors.action.active,
                        },
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderColor: themeColors.primary.main,
                    borderRadius: '6px',
                    borderWidth: '0.125rem',
                    '&.Mui-selected': {
                        backgroundColor: themeColors.secondary.main,
                        color: themeColors.secondary.contrastText,
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: themeColors.secondary.dark,
                        color: themeColors.secondary.contrastText,
                    },
                    '&:hover': {
                        backgroundColor: themeColors.action.hover,
                        borderColor: themeColors.primary.main,
                    },
                    textTransform: 'none',
                },
                sizeMedium: {
                    ...theme.typography.buttonMedium,
                    padding: '10px 12px 10px 12px',
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    boxShadow: `1px 1px 0px ${themeColors.primary.main}`,
                    width: 'max-content',
                },
                grouped: {
                    '&:not(:first-of-type)': {
                        borderLeft: '1px solid #123133',
                    },
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    ...theme.typography.tooltip,
                    backgroundColor: themeColors.action.active,
                    borderRadius: '6px',
                    maxWidth: '480px',
                    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
                },
                arrow: {
                    ':before': {
                        backgroundColor: themeColors.action.active,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: theme.typography.fontFamily,
                },
                h3: theme.typography.h3,
            },
        },
        MuiYearPicker: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    // Fix the height so the year picker is the same height as the day picker.
                    height: 280,
                    padding: `0 ${theme.spacing(2)}`,
                },
            },
        },
    },
});

export default theme;
