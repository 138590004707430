import { useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import Button from 'domains/core/components/Button';
import { FolderBreadcrumbData } from 'domains/core/components/folders/types';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import useMoveItem from 'hooks/mutations/useMoveItem';
import { Folder, FolderLocation } from 'models/types';
import { isRootFolder, removeTypeFromId, rootFolderId } from 'utils/folders';
import CurentLocationText from './CurentLocationText';
import { useMoveItemConfig } from './useMoveItemConfig';
import ModalItem from './ModalItem';
import ModalPreviewItem from './ModalPreviewItem';

type Props = {
    activeItem: Folder;
    activeFolder: FolderBreadcrumbData;
    folderLocation: FolderLocation;
    handleModalClose: () => void;
    isOpen: boolean;
    refetch: () => void;
    resetActiveItem: () => void;
};

const MoveItemModal = ({
    activeItem,
    activeFolder,
    folderLocation,
    handleModalClose,
    isOpen,
    refetch,
    resetActiveItem,
}: Props) => {
    const [messages, setMessages] = useState({
        error: '',
        info: '',
        success: '',
    });
    const [locations, setLocations] = useState({
        future: null as Folder | null,
        previewed: null as Folder | null,
    });

    const { mutate: mutateMoveItem, isError, isLoading, isSuccess } = useMoveItem(folderLocation, {
        onError: ({ message }) => {
            setMessages((prev) => ({ ...prev, error: message }));
            handleModalCloseAndReset();
        },
        onSuccess: (response) => {
            setMessages((prev) => ({
                ...prev,
                success: `"${activeItem?.name}" successfully moved to "${locations.future?.name}."`,
            }));
            handleModalCloseAndReset();
            refetch();
            const { targetFolderId } = response;
            const contentViewParams = {
                newFolderId: targetFolderId === null ? rootFolderId : targetFolderId,
            };
            changeViewParams(contentViewParams);
        },
    });

    const moveItem = useCallback(
        (type: string) => {
            setMessages((prev) => ({
                ...prev,
                info: `Moving "${activeItem?.name}" to "${locations.future?.name}"`,
            }));
            const requestBody = {
                id: removeTypeFromId(activeItem?.id),
                type,
                ...(activeFolder.id !== rootFolderId && { currFolderId: activeFolder?.id }),
                ...(locations.future?.id !== rootFolderId && { targetFolderId: locations.future?.id }),
            };
            mutateMoveItem(requestBody);
        },
        [
            activeItem?.id,
            activeItem?.name,
            activeFolder.id,
            locations.future?.id,
            locations.future?.name,
            mutateMoveItem,
        ]
    );

    const moveItemConfigMap = useMoveItemConfig({
        activeItem,
        folderLocation,
        isOpen,
        moveItem,
        previewedLocation: locations.previewed,
    });

    const { changeViewParams, handleMoveItem, locationData, previewData, rootFolder, Icon } =
        moveItemConfigMap[folderLocation] || moveItemConfigMap.default;

    const CurrentLocationTextIcon = isRootFolder(activeFolder?.id) ? Icon : moveItemConfigMap.default.Icon;

    const ModalItems = () => {
        const getIsItemSelected = (item: Folder) => item?.id === locations.future?.id;
        const setFutureLocation = (future: Folder) => setLocations((prev) => ({ ...prev, future }));
        const setPreviewedLocation = (previewed: Folder) => setLocations((prev) => ({ ...prev, previewed }));

        if (locations.previewed) {
            return (
                <ModalPreviewItem
                    data={previewData}
                    previewedLocation={locations.previewed}
                    resetPreview={() => {
                        setPreviewedLocation(null);
                        setFutureLocation(null);
                    }}
                    rootFolder={rootFolder}
                />
            );
        }

        return (
            <Stack sx={{ padding: '1rem 1rem 0' }}>
                <ModalItem
                    key={rootFolder.id}
                    activeFolder={activeFolder}
                    DomainIcon={Icon}
                    isRowSelected={getIsItemSelected(rootFolder)}
                    rootFolder={rootFolder}
                    setFutureLocation={setFutureLocation}
                    setPreviewedLocation={setPreviewedLocation}
                />
                {locationData?.map((location: Folder) => (
                    <ModalItem
                        key={location.id}
                        activeFolder={activeFolder}
                        isRowSelected={getIsItemSelected(location)}
                        row={location}
                        setFutureLocation={setFutureLocation}
                        setPreviewedLocation={setPreviewedLocation}
                    />
                ))}
            </Stack>
        );
    };

    const handleModalCloseAndReset = useCallback(() => {
        setLocations({ future: null, previewed: null });
        resetActiveItem();
        handleModalClose();
    }, [resetActiveItem, handleModalClose]);

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={messages.error} />}
            {isLoading && <InfoSnackbar successMessage={messages.info} />}
            {isSuccess && <SuccessSnackbar successMessage={messages.success} />}
            <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleModalCloseAndReset}>
                <DialogTitle>Move "{activeItem?.name}"</DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <CurentLocationText activeFolder={activeFolder} Icon={CurrentLocationTextIcon} />
                    {ModalItems()}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleModalCloseAndReset}>
                        Cancel
                    </Button>
                    <Button
                        data-testid="move-button"
                        disabled={!locations.future}
                        variant="contained"
                        onClick={handleMoveItem}
                    >
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MoveItemModal;
