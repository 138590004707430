export const rootFolderId = -1;

export const isRootFolder = (folderId: number) => folderId === rootFolderId;

export const getIsFolderContentActive = (folderId: number) => folderId !== rootFolderId;

export const removeTypeFromId = (id?: string | number) => {
    if (!id) return null;
    if (typeof id === 'number') {
        return id;
    }
    return Number(String(id).split(':')[1]);
};
