import { useState } from 'react';
import FolderNamingDialog from 'domains/core/components/folders/FolderNamingDialog';
import { FolderBreadcrumbData } from 'domains/core/components/folders/types';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import { ContentType } from 'domains/content/types';
import { SegmentTableItemType } from 'domains/segments/types';
import { Folder, FolderLocation } from 'models/types';
import useRenameFolder from 'hooks/mutations/useRenameFolder';

type Props = {
    activeFolder: Folder | FolderBreadcrumbData;
    folderLocation: FolderLocation;
    handleModalClose: () => void;
    isOpen: boolean;
    refetch: () => void;
    resetActiveFolder: () => void;
};

const RenameFolderModal = ({
    activeFolder,
    folderLocation,
    handleModalClose,
    isOpen,
    refetch,
    resetActiveFolder,
}: Props) => {
    const [errorMessage, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const { mutate: renameFolder, isLoading } = useRenameFolder(folderLocation);

    const isAudienceFolder = folderLocation === FolderLocation.AUDIENCE_FOLDER;
    const folderType = isAudienceFolder ? SegmentTableItemType.FOLDER : ContentType.FOLDER;

    const renameFolderWrapper = (name: string) => {
        setError('');
        setSuccessMessage('');
        const body = {
            name: name,
            id: activeFolder?.id,
            type: folderType,
        };

        renameFolder(body, {
            onSuccess: () => {
                setSuccessMessage(`"${activeFolder.name}" renamed to "${name}"`);
                resetActiveFolder();
                refetch();
            },
            onError: (error: Error) => {
                setError(error.message);
                resetActiveFolder();
            },
        });
        handleModalClose();
    };

    return (
        <>
            {errorMessage && <ErrorSnackbar errorMessage={errorMessage} />}
            {successMessage && <SuccessSnackbar successMessage={successMessage} />}
            <FolderNamingDialog
                initialValue={activeFolder?.name}
                isDialogVisible={isOpen}
                disableActionButton={isLoading}
                onClose={handleModalClose}
                onSubmit={renameFolderWrapper}
                submitButtonText="Rename"
                title="rename"
            />
        </>
    );
};

export default RenameFolderModal;
