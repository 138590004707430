import { ContentTypeForComparison } from 'domains/content/types';
import { SegmentTableItemType } from 'domains/segments/types';

export type FolderLineage = {
    id: number;
    name: string;
    level: number;
};

export type FolderBreadcrumbData = {
    id: number;
    name: string;
    type: ContentTypeForComparison | SegmentTableItemType;
    level?: number;
};

export enum FolderModalTypes {
    DELETE = 'Delete',
    RENAME = 'Rename',
    MOVE = 'Move',
}
