import { FolderBreadcrumbData } from 'domains/core/components/folders/types';
import { SvgIconProps, Typography } from '@mui/material';

type Props = {
    activeFolder: FolderBreadcrumbData;
    Icon: React.ComponentType<SvgIconProps>;
};

const CurrentLocationText = ({ activeFolder, Icon }: Props) => (
    <Typography variant="body2" sx={{ padding: '0 2rem 1rem' }}>
        Current Location:
        <strong>
            <Icon sx={{ margin: '0 4px -6px 16px' }} /> {activeFolder.name}
        </strong>
    </Typography>
);

export default CurrentLocationText;
