import { DataObject } from '@mui/icons-material';
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { setSMSContent } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { SmsSendInput } from 'domains/campaigns/types';
import { MergeTag } from 'domains/content/types';
import TextField from 'domains/core/components/TextField';
import Menu from 'domains/core/components/Menu';
import useContactColumns from 'hooks/queries/useContactColumns';
import { getFriendlyName, getMergeTags } from 'utils';
import SMSSendTest from './SMSSendTest';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    isContentMinLength: boolean;
};

const ContentWrapper = ({ activeSendIndex, campaignState, dispatchCampaignAction, isContentMinLength }: Props) => {
    const currentSend = campaignState.sends[activeSendIndex];
    const { content } = currentSend as SmsSendInput;

    const [cursorPosition, setCursorPosition] = useState(0);
    const inputRef = useRef(null);

    const [selectedToggleOption, setSelectedToggleOption] = useState(['']);

    const [isContentPreviewed, setIsContentPreviewed] = useState(false);
    const [contentForPreview, setContentForPreview] = useState('');

    const { data: contactColumns, isSuccess: isContactColumnsSuccess } = useContactColumns();

    const mergeTags = useMemo(() => {
        if (isContactColumnsSuccess) return getMergeTags(contactColumns);
    }, [contactColumns, isContactColumnsSuccess]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.selectionStart = cursorPosition;
            inputRef.current.selectionEnd = cursorPosition;
        }
    }, [cursorPosition]);

    const resetContentPreview = () => {
        setIsContentPreviewed(false);
        setSelectedToggleOption([]);
    };

    const handleContentUpdate = (e: any, value: string) => {
        setCursorPosition(e.target.selectionStart);
        dispatchCampaignAction(setSMSContent(value, activeSendIndex));
        setContentForPreview(value);
    };

    const handleContentFieldChange = (e: any) => {
        if (isContentPreviewed) {
            resetContentPreview();
        } else {
            handleContentUpdate(e, e.target.value);
        }
    };

    const onClickMergeTagItem = (e: any, value?: string) => {
        if (isContentPreviewed) resetContentPreview();
        inputRef.current.focus();

        const addMergeTagToContent = () => {
            if (cursorPosition === 0) return value + content.slice(cursorPosition);

            if (cursorPosition) {
                return content.slice(0, cursorPosition) + value + content.slice(cursorPosition);
            } else {
                return content.slice(0, cursorPosition) + value;
            }
        };

        const newContent = addMergeTagToContent();
        handleContentUpdate(e, newContent);
    };

    const orderedMergeTags = mergeTags?.sort((a, b) => a.value.localeCompare(b.value));
    const mergeTagOptions = orderedMergeTags?.map(({ name, value, previewValue }: MergeTag) => ({
        name: getFriendlyName(name),
        value,
        previewValue,
        onClick: (e: any) => onClickMergeTagItem(e, value),
    }));

    const previewToggleValue = 'preview merge tags';
    const onContentPreviewToggleChange = (e: any, values: string[]) => {
        if (values.includes(previewToggleValue)) {
            setSelectedToggleOption(values);
        } else if (selectedToggleOption.includes(previewToggleValue)) {
            setSelectedToggleOption([]);
        }
    };

    const getContentForPreview = () => {
        const regex = /{{.*?}}/g;
        const matches = content.match(regex);

        if (matches) {
            let newContent: string = content;

            for (const match of matches) {
                const previewValue = mergeTags.find((tag: MergeTag) => tag.value === match)?.previewValue;
                newContent = newContent.replaceAll(match, previewValue);
            }

            return newContent;
        }
        return content;
    };

    const handlePreviewClick = () => {
        setIsContentPreviewed(!isContentPreviewed);
        setContentForPreview(isContactColumnsSuccess ? getContentForPreview() : '');
    };

    return (
        <Stack rowGap={5}>
            <TextField
                counterCurrent={content?.length}
                counterMax={1600}
                fullWidth
                inputProps={{ maxLength: 1600, 'data-testid': 'content-field' }}
                inputRef={inputRef}
                label="Content"
                multiline
                onBlur={(e) => setCursorPosition(e.target.selectionStart)}
                onChange={handleContentFieldChange}
                required
                rows={3}
                value={isContentPreviewed ? contentForPreview : content}
                withCounter
            />
            <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                <ToggleButtonGroup value={selectedToggleOption} onChange={onContentPreviewToggleChange}>
                    <Menu
                        items={isContactColumnsSuccess ? mergeTagOptions : []}
                        button={
                            <ToggleButton
                                value="add merge tag"
                                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            >
                                <Tooltip aria-label="add merge tags" title="Add merge tags">
                                    <Stack alignItems="center">
                                        <DataObject />
                                    </Stack>
                                </Tooltip>
                            </ToggleButton>
                        }
                    />
                    <ToggleButton value={previewToggleValue} onClick={handlePreviewClick}>
                        <Tooltip aria-label="preview merge tags" title="Preview merge tags">
                            <span>Preview</span>
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
                <SMSSendTest content={content} isContentMinLength={isContentMinLength} />
            </Stack>
        </Stack>
    );
};

export default ContentWrapper;
