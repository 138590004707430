import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumbers = (numbers: string) => {
    const split = numbers.split(', ').filter(Boolean);
    const trim = split.map((number) => number.trim());
    const parse = trim.map((number) => {
        const hasLetters = /[a-zA-Z]/.test(number);
        let parsedNumber = '';
        try {
            parsedNumber = parsePhoneNumber(number, 'US').number;
        } catch (e) {}
        return hasLetters ? '' : parsedNumber;
    });
    return parse;
};

export const arePhoneNumbersValid = (numbers: string[]) => {
    for (const number of numbers) {
        if (!isValidPhoneNumber(number)) {
            return false;
        }
    }
    return true;
};

export const arePhoneNumbersMobile = (numbers: string[]) => {
    for (const number of numbers) {
        const parsedNumber = parsePhoneNumber(number, 'US');

        if (parsedNumber.getType() !== 'FIXED_LINE_OR_MOBILE') {
            return false;
        }
    }
    return true;
};
