import { Fragment } from 'react';
import {
    Campaign,
    CampaignChannelLabels,
    CampaignChannels,
    Send,
    SendRecurrenceFrequency,
    SplitActions,
    SplitTypes,
} from 'domains/campaigns/types';
import ContentDetail from 'domains/content/components/ContentDetail';
import DetailItem from 'domains/core/components/DetailItem';
import useSegment from 'hooks/queries/useSegment';
import { getSplitDescription } from '../utils';

type ConditionalSplitProps = {
    campaign: Campaign;
    index: number;
    send: Send;
};

const ConditionalSplitDetailItem = ({ campaign, index, send }: ConditionalSplitProps) => {
    // This renders before a "meets criteria" split action. If the "meets criteria" action is exit,
    // we will try to use the segment id from the "does not meet criteria" action. If, for some reason,
    // the action is _also_ exit, there will be no segment id.
    const splitSegmentId = send?.segmentId || campaign.sends[index + 1]?.segmentId;
    const { data: segmentData } = useSegment(splitSegmentId, { enabled: !!splitSegmentId });

    const splitDescription = splitSegmentId ? `${getSplitDescription(segmentData)}` : 'no split description available';

    return <DetailItem header="Conditional Split" description={splitDescription} />;
};

type SendProps = {
    contentPreviewNodeId: string | number;
    handlePreviewClick: (id: number | null) => void;
    recurrenceFrequency: SendRecurrenceFrequency;
    send: Send;
    index: number;
};

const SendDetailItem = ({ contentPreviewNodeId, handlePreviewClick, recurrenceFrequency, send, index }: SendProps) => {
    const { channel } = send;

    const getHeader = () => {
        const sendChannel = CampaignChannelLabels[send?.channel];
        if (!send.splitType) return sendChannel;
        if (send.splitType === SplitTypes.MEETS) return 'Meets Criteria';
        return 'Does Not Meet Criteria';
    };
    const header = getHeader();

    if (send.splitAction === SplitActions.EXIT) {
        return <DetailItem key={`${send.splitType}-${index}`} header={header} description="exit curation" />;
    }

    const contentId = channel === CampaignChannels.DIRECT_MAIL ? send.directMailContentId : send.id;

    if (channel === CampaignChannels.SMS) {
        return (
            <ContentDetail
                key={index}
                channel={channel}
                contentId={contentId}
                firstEmailRecurrenceFrequency={recurrenceFrequency}
                header={header}
                isCampaign
                isFirstEmail={index === 0}
                isPreviewOpen={false}
                isSMS
                recurrenceFrequency={send.recurrenceFrequency}
                scheduledEndTimestamp={send.scheduledEndTimestamp}
                scheduledTimestamp={send.scheduledTimestamp}
                timeDelay={send.parentAudienceDelayInterval}
            />
        );
    }

    const isPreviewOpen = contentPreviewNodeId && send?.id === contentPreviewNodeId;
    const onClick = () => handlePreviewClick(isPreviewOpen ? null : send.id);

    return (
        <ContentDetail
            key={index}
            channel={channel}
            contentId={contentId}
            firstEmailRecurrenceFrequency={recurrenceFrequency}
            header={header}
            isCampaign
            isFirstEmail={index === 0}
            isPreviewOpen={isPreviewOpen}
            recurrenceFrequency={send.recurrenceFrequency}
            scheduledEndTimestamp={send.scheduledEndTimestamp}
            scheduledTimestamp={send.scheduledTimestamp}
            timeDelay={send.parentAudienceDelayInterval}
            onClick={onClick}
        />
    );
};

type CustomCampaignDetailsProps = {
    campaign: Campaign;
    contentPreviewNodeId?: string | number;
    handlePreviewClick: (contentNodeId: number) => void;
};

const CustomCampaignDetails = ({ campaign, contentPreviewNodeId, handlePreviewClick }: CustomCampaignDetailsProps) => (
    <>
        {campaign.sends.map((send: Send, index: number) => (
            <Fragment key={`send-${index}`}>
                {/* We want to add an indication of a split before split sends. At present, conditional splits
                begin with meets criteria. This may change as splits become more complex. */}
                {send.splitType === SplitTypes.MEETS && (
                    <ConditionalSplitDetailItem campaign={campaign} index={index} send={send} />
                )}
                <SendDetailItem
                    contentPreviewNodeId={contentPreviewNodeId}
                    handlePreviewClick={handlePreviewClick}
                    index={index}
                    recurrenceFrequency={campaign.sends[0]?.recurrenceFrequency}
                    send={send}
                />
            </Fragment>
        ))}
    </>
);

export default CustomCampaignDetails;
