import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useQuery from '../useQuery';
import QueryKeys from './keys';
import { Folder } from 'models/types';

type UseSegmentsWithFoldersParams = {
    folderId?: number;
    type?: string;
    reactQueryOptions?: UseQueryOptions<Folder[], Error>;
};

const useSegmentsWithFolders = ({ folderId = -1, type, reactQueryOptions }: UseSegmentsWithFoldersParams = {}) => {
    const axiosOptions = {
        ...(type && { params: { type } }),
    };

    const folderPath = folderId === -1 ? '' : `/${folderId}`;

    return useQuery(
        QueryKeys.SEGMENT_FOLDERS(folderId, type),
        `${CuredApiPaths.SEGMENT_FOLDERS}${folderPath}`,
        reactQueryOptions,
        axiosOptions
    );
};

export default useSegmentsWithFolders;
