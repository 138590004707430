import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
    setHasRecommendedTime,
    setRecurrenceFrequency,
    setScheduledTimestamp,
} from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignChannels, EmailSendInputState, SendRecurrenceFrequency, SmsSendInput } from 'domains/campaigns/types';
import { getRecurrenceLabel } from 'domains/campaigns/utils';
import DropdownButton from 'domains/core/components/DropdownButton';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import InputLabel from 'domains/core/components/InputLabel';
import theme from 'theme';
import { disabledMessage } from '../RecommendedTimeSwitch';
import { getRecommendedTime } from '../utils/getRecommendedTime';

const dropdownButtonStyles = {
    backgroundColor: theme.palette.action.active,
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
};

export type Props = {
    currentSend: EmailSendInputState | SmsSendInput;
    dispatchCampaignAction: React.Dispatch<any>;
    sendDate: DateTime;
    setEndDate: (payload: DateTime) => void;
    setHasEndDate: (payload: boolean) => void;
    setIsRecommendedTimeSwitchTouched: (payload: boolean) => void;
    setSendTime: (payload: DateTime) => void;
};

const RecurrenceDropdown = ({
    currentSend,
    dispatchCampaignAction,
    sendDate,
    setEndDate,
    setHasEndDate,
    setIsRecommendedTimeSwitchTouched,
    setSendTime,
}: Props) => {
    const { channel, recurrenceFrequency } = currentSend;
    const hasRecommendedTime = channel === CampaignChannels.EMAIL && currentSend.hasRecommendedTime;

    const handleDoesNotRepeatSelection = () => {
        const recurrence = SendRecurrenceFrequency.DOES_NOT_REPEAT;
        dispatchCampaignAction(setRecurrenceFrequency(recurrence));
        const newDatetime = getRecommendedTime(sendDate, recurrence);

        if (hasRecommendedTime) {
            setSendTime(newDatetime);
            dispatchCampaignAction(setScheduledTimestamp(newDatetime.toISO(), 0));
        }

        setHasEndDate(false);
        setEndDate(null);
        setIsRecommendedTimeSwitchTouched(true);
    };

    const handleContinuousSelection = () => {
        dispatchCampaignAction(setRecurrenceFrequency(SendRecurrenceFrequency.CONTINUOUS));
        dispatchCampaignAction(setHasRecommendedTime(false));

        setIsRecommendedTimeSwitchTouched(true);
    };

    const optionsRecurrenceFrequency = [
        {
            label: getRecurrenceLabel(SendRecurrenceFrequency.DOES_NOT_REPEAT).toLowerCase(),
            onClick: handleDoesNotRepeatSelection,
        },
        {
            label: (
                <InputLabel
                    label="Continual"
                    labelIconTooltipText="Continual sends are delivered when the audience criteria is met."
                />
            ),
            onClick: handleContinuousSelection,
        },
        {
            label: getRecurrenceLabel(SendRecurrenceFrequency.DAILY).toLowerCase(),
            onClick: () => handleRecurrenceSelection(SendRecurrenceFrequency.DAILY),
        },
        {
            label: getRecurrenceLabel(SendRecurrenceFrequency.WEEKLY, sendDate?.toISO()).toLowerCase(),
            onClick: () => handleRecurrenceSelection(SendRecurrenceFrequency.WEEKLY),
        },
        {
            label: getRecurrenceLabel(SendRecurrenceFrequency.EVERY_WEEKDAY).toLowerCase(),
            onClick: () => handleRecurrenceSelection(SendRecurrenceFrequency.EVERY_WEEKDAY),
        },
    ];

    const handleRecurrenceSelection = (recurrence: SendRecurrenceFrequency) => {
        dispatchCampaignAction(setRecurrenceFrequency(recurrence));

        if (hasRecommendedTime) {
            const newDatetime = getRecommendedTime(sendDate, recurrence);
            setSendTime(newDatetime);
            dispatchCampaignAction(setScheduledTimestamp(newDatetime.toISO(), 0));
        }

        setIsRecommendedTimeSwitchTouched(true);
    };

    const renderRecommendedTimeSnackbar = () => {
        if (!hasRecommendedTime || recurrenceFrequency === SendRecurrenceFrequency.DOES_NOT_REPEAT) return;

        if (recurrenceFrequency === SendRecurrenceFrequency.CONTINUOUS) {
            return (
                <InfoSnackbar
                    key="continuous-recurrence-recommended-time-disabled"
                    successMessage={
                        <Typography color="info.content" component="span" variant="body2">
                            {disabledMessage}
                        </Typography>
                    }
                />
            );
        }

        return (
            <InfoSnackbar
                key={`${recurrenceFrequency}-recurrence-recommended-time-updated`}
                successMessage={
                    <Typography color="info.content" component="span" variant="body2">
                        Cured's recommended time updated based on{' '}
                        <Typography color="info.dark" component="span" variant="body2">
                            recurring send
                        </Typography>
                        .
                    </Typography>
                }
            />
        );
    };

    return (
        <>
            {renderRecommendedTimeSnackbar()}
            <Box>
                <DropdownButton
                    sx={dropdownButtonStyles}
                    data-testid="recurrence-button"
                    label={getRecurrenceLabel(recurrenceFrequency, sendDate.toISO())}
                    options={optionsRecurrenceFrequency}
                    variant="contained"
                    size="small"
                />
            </Box>
        </>
    );
};

export default RecurrenceDropdown;
