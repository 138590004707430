import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import { FolderOpenOutlined, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { Folder, FolderLocation } from 'models/types';
import { ContentType, ContentTypeEnumForBackend, RootContentFolder } from 'domains/content/types';
import { RootSegmentFolder, SegmentTableItemType } from 'domains/segments/types';
import useContentQueryManager from 'hooks/useContentQueryParams';
import useSegmentQueryManager from 'hooks/useSegmentQueryManager';
import useContentWithFolders from 'hooks/queries/useContentWithFolders';
import useSegmentsWithFolders from 'hooks/queries/useSegmentsWithFolders';
import { contentTypeToSnakeCase } from 'pages/ContentPage/ContentTable/utils';

export type Props = {
    activeItem: Folder;
    folderLocation: FolderLocation;
    isOpen: boolean;
    moveItem: (type: string) => void;
    previewedLocation: Folder;
};

const useFolderData = (
    activeItem: Folder,
    isFolder: boolean,
    isOpen: boolean,
    previewedLocation: Folder,
    useFoldersHook: any
) => {
    const { data: locationData } = useFoldersHook({
        type: isFolder ? ContentType.FOLDER : SegmentTableItemType.FOLDER,
        reactQueryOptions: { enabled: isFolder && !!activeItem && isOpen },
    });
    const { data: folderPreviewData } = useFoldersHook({
        folderId: previewedLocation?.id,
        reactQueryOptions: { enabled: isFolder && !!previewedLocation },
    });

    return { locationData, folderPreviewData };
};

export const useMoveItemConfig = ({ activeItem, folderLocation, isOpen, moveItem, previewedLocation }: Props) => {
    const { changeViewParams: changeContentViewParams } = useContentQueryManager();
    const { changeViewParams: changeSegmentViewParams } = useSegmentQueryManager();

    const isContentFolder = folderLocation === FolderLocation.CONTENT_FOLDER;
    const isSegmentFolder = folderLocation === FolderLocation.AUDIENCE_FOLDER;

    const contentData = useFolderData(activeItem, isContentFolder, isOpen, previewedLocation, useContentWithFolders);
    const segmentData = useFolderData(activeItem, isSegmentFolder, isOpen, previewedLocation, useSegmentsWithFolders);

    const moveContentFolder = () => {
        const contentToMoveType = contentTypeToSnakeCase(activeItem.type as keyof typeof ContentTypeEnumForBackend);
        moveItem(contentToMoveType);
    };
    const moveSegmentFolder = () => {
        moveItem(activeItem.type);
    };

    return {
        [FolderLocation.CONTENT_FOLDER]: {
            changeViewParams: changeContentViewParams,
            handleMoveItem: moveContentFolder,
            Icon: ContentIcon,
            locationData: contentData.locationData,
            previewData: contentData.folderPreviewData,
            rootFolder: RootContentFolder as Folder,
        },
        [FolderLocation.AUDIENCE_FOLDER]: {
            changeViewParams: changeSegmentViewParams,
            handleMoveItem: moveSegmentFolder,
            Icon: SupervisedUserCircleOutlined,
            locationData: segmentData.locationData,
            previewData: segmentData.folderPreviewData,
            rootFolder: RootSegmentFolder as Folder,
        },
        default: {
            changeViewParams: () => {},
            handleMoveItem: () => {},
            Icon: FolderOpenOutlined,
            locationData: [] as Folder[],
            previewData: [] as Folder[],
            rootFolder: {} as Folder,
        },
    };
};
