/**
 * Paths
 */
export enum URLPaths {
    CONTACTS = '/contacts',
    CONTENT = '/content',
    CONTENT_CREATE_EMAIL = '/content/create/email',
    CONTENT_CREATE_PAGE = '/content/create/page',
    CURATIONS = '/curations',
    CAMPAIGNS = '/campaigns',
    CURATIONS_CONDITIONAL_SPLIT = '/curations/email/conditional-split',
    CURATIONS_CONDITIONAL_SPLIT_SEGMENT = '/curations/email/conditional-split/audience',
    CURATIONS_CONDITIONAL_SPLIT_1_CONTENT = '/curations/email/conditional-split/1/content',
    CURATIONS_CONDITIONAL_SPLIT_SCHEDULE = '/curations/email/conditional-split/schedule',
    CURATIONS_CONDITIONAL_SPLIT_DELAY = '/curations/email/conditional-split/delay',
    CURATIONS_CONDITIONAL_SPLIT_SEGMENTS = '/curations/email/conditional-split/segments',
    CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION = '/curations/email/conditional-split/segments-meets/action',
    CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT = '/curations/email/conditional-split/segments-meets/content',
    CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION = '/curations/email/conditional-split/segments-meets-not/action',
    CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT = '/curations/email/conditional-split/segments-meets-not/content',
    CURATIONS_CONDITIONAL_SPLIT_PUBLISH = '/curations/email/conditional-split/publish',
    CURATIONS_CUSTOM = '/curations/custom',
    CURATIONS_DETAILS = '/curations/details',
    CURATIONS_EMAIL_SERIES = '/curations/email/email-series',
    CURATIONS_LANDING = '/curations/create',
    CURATIONS_SINGLE_SEND = '/curations/email/single-send',
    CURATIONS_SMS = '/curations/sms',
    HOME_PAGE = '/',
    INSIGHTS = '/insights',
    SEGMENTS = '/audiences',
    SEGMENT_CREATE = '/audiences/create',
    SEGMENTS_CONTACTS = '/audiences/contacts',
    UNSUBSCRIBE = '/unsubscribe',
    VALUE = '/value',
    VALUE_CREATE = '/value/create',
    VALUE_SEND_SPECIALTY = '/value/create/send-specialty',
    VALUE_CONVERSION_EVENT = '/value/create/conversion-event',
    VALUE_CONVERSION_METRIC = '/value/create/conversion-metric',
    VALUE_CONVERSION_VALUE = '/value/create/conversion-value',
    VALUE_DETAILS = '/value/details',
    INNOVACCER_LOGIN = '/innovaccer/login',
}

export enum CuredApiPaths {
    AGE_PREDICTIONS = 'predictions/age',
    APP_SENDS = 'app/sends',
    APP_SENDS_VALUE_ASSUMPTION = 'app/sends/for-value-assumption',
    APP_CLICK_APPOINTMENT_VALUE = 'app/click-appointment-value',
    ASSESSMENTS = 'assessments',
    ASSESSMENT_CONSENT = 'assessments/consent',
    AUDIENCE_COUNT = 'audiences/count',
    CAMPAIGNS = 'campaigns',
    CAMPAIGN_TEMPLATES = 'campaign-templates',
    CLICK_APPOINTMENT_VALUE_ASSUMPTIONS = 'click-appointment-value-assumptions',
    CONTACT_COLUMNS = 'contacts/columns',
    CONTENT_EDITOR_CONFIG_EMAIL = 'content-editor/config/email',
    CONTENT_EDITOR_CONFIG_PAGE = 'content-editor/config/page',
    CONTENT_PERSONALIZATION_DEFAULTS = '/content-editor/personalization-default',
    CONTACT_FIELDS = 'contacts/fields',
    CONTACTS = 'contacts',
    CONTENT = 'content',
    CONTENT_FOLDER_LINEAGE = 'content/lineage',
    CONTENT_FOLDERS = 'content/folder',
    CONTENT_MOVE = 'content/move',
    CONTENT_RENAME = 'content/rename',
    CURATIONS = 'curations',
    CSV_TO_CLIENT_DB = 'csv-to-client-db',
    DATA_VIS_DASHBOARD_URL = 'data-vis',
    DIRECT_MAIL = 'direct-mail',
    DIRECT_MAIL_SAVE_PDF = 'direct-mail/savePdf',
    FEATURE_SETTINGS = 'feature-settings',
    LD_HASH = 'feature-flag/hash',
    LANDING_PAGES = 'landing-pages',
    SEGMENT_FILTER_ENGAGEMENT_OPERATORS = 'segments/filters/engagement/operators',
    SEGMENT_FILTERS = 'segments/filters',
    SEGMENTS_PREVIEW_COUNT = 'segments/preview/count',
    SEGMENT_FOLDER_LINEAGE = 'segments/lineage',
    SEGMENT_FOLDERS = 'segments/folder',
    SEGMENTS = 'segments',
    SEGMENTS_FOLDER_RENAME = 'segments/rename',
    SEGMENT_MOVE = 'segments/move',
    SENDS = 'sends',
    SENDER_PROFILES = 'sender-profiles',
    STATE_PREDICTIONS = 'predictions/state',
    TASKS = 'tasks',
    TEMPLATES = 'templates',
    TEST_EMAILS = 'test-emails',
    TEST_SMS = 'test-sms',
    TOKENS_UPLOAD = 'tokens/upload',
    IMPORTS = 'imports',
    CLIENT_UAM_CONFIG = 'clients/innovaccer-config',
    INNOVACCER_LOGIN = 'app/innovaccer/login',
    INNOVACCER_USER = 'app/innovaccer/user',
    REPORT_CONTACTS_COUNT = '/app/report/contact/count',
    REPORT_CONTACTS_DOMAINS = '/app/report/contact/domains',
    REPORT_CONTACTS_LOCATIONS = '/app/report/contact/locations',
    REPORT_CONTACTS_SUBSCRIPTION_STATUS = '/app/report/contact/subscription-status',
    REPORT_CONTACTS_SUBSCRIBES = '/app/report/contact/subscribes',
    REPORT_CONTACTS_UNSUBSCRIBES = '/app/report/contact/unsubscribes',
    REPORT_CONTACTS_GENDER = '/app/report/contact/gender',
    REPORT_CONTACTS_AGES = '/app/report/contact/ages',
    REPORT_CURATION_STATS = '/app/report/curation/stats',
    REPORT_CURATION_MESSAGES = '/app/report/curation/messages-over-time',
    REPORT_CURATION_DEVICE_OPENS = '/app/report/curation/device-opens',
    REPORT_CURATION_DEVICE_CLICKS = '/app/report/curation/device-clicks',
    REPORT_CURATION_TOP_CLICKS = '/app/report/curation/top-clicks',
    REPORT_CURATION_UNIQUE_CLICKS = '/app/report/curation/unique-clicks',
    REPORT_CURATION_UNIQUE_OPENS = '/app/report/curation/unique-opens',
    REPORT_CURATION_ENGAGEMENT = '/app/report/curation/engagement',
    REPORT_CURATION_ENGAGEMENT_FUNNEL = '/app/report/curation/engagement-funnel',
    REPORT_CURATION_FILTER_DATA = '/app/report/curation/active-ids',
    REPORT_OVERVIEW_STATS = '/app/report/overview/stats',
    REPORT_UNIQUE_RATES = '/app/report/overview/unique-rates',
    REPORT_OVERVIEW_TOP_OPENS = '/app/report/overview/top-opens',
    REPORT_OVERVIEW_TOP_CLICKS = '/app/report/overview/top-clicks',
    REPORT_VALUE_FILTERS = '/app/report/value/filters',
    REPORT_VALUE_STATS = '/app/report/value/stats',
    REPORT_VALUE_SUMMARY = '/app/report/value/summary',
    REPORT_VALUE_UNIQUE_CLICKS = '/app/report/value/unique-clicks',
    REPORT_SMS_STATS = '/app/report/sms/stats',
    REPORT_SMS_UNSUBSCRIBES = '/app/report/sms/unsubscribes',
    REPORT_SMS_SUBSCRIBES = '/app/report/sms/subscribes',
    REPORT_SMS_MESSAGES_OVER_TIME = '/app/report/sms/messages-over-time',
    REPORT_SMS_METRICS = '/app/report/sms/metrics',
    REPORT_SMS_FILTER_DATA = '/app/report/sms/active-ids',
}

/**
 * Campaign
 */
export enum CampaignStatus {
    CANCELED = 'CANCELED',
    COMPLETED = 'COMPLETED',
    DRAFT = 'DRAFT',
    FAILED = 'FAILED',
    FINISHING = 'FINISHING',
    PAUSED = 'PAUSED',
    RESUMED = 'RESUMED',
    SCHEDULED = 'SCHEDULED',
    SENDING = 'SENDING',
    STOPPED = 'STOPPED',
}

export enum CampaignType {
    SINGLE_SEND = 'single send',
    TIME_DELAY = 'time delay',
    CONDITIONAL_SPLIT = 'conditional split',
    FLEXIBLE = 'flexible',
}

/**
 * Send Job
 */
export enum SendJobStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN PROGRESS',
    DELIVERED = 'DELIVERED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
}

export enum AudienceStatus {
    PENDING = 'pending',
    ACTIVE = 'active',
    COMPLETE = 'complete',
    FAILED = 'failed',
    CANCELED = 'canceled',
    PAUSED = 'paused',
}

/**
 *  Flow
 */
export enum CurationNavState {
    PREVIEW = 'PREVIEW',
    OPEN = 'OPEN',
    IN_PROGRESS = 'IN_PROGRESS',
    IN_PROGRESS_CURRENT = 'IN_PROGRESS_CURRENT',
    COMPLETED = 'COMPLETED',
}

export enum CurationSegments {
    DID_CLICK = 'did click',
    DID_OPEN = 'did open',
}

export enum CurationSegmentsAction {
    SEND_EMAIL = 'send email',
    EXIT_CURATION = 'exit curation',
}

export enum CurationStep {
    SEGMENT = 'audience',
    CURATION = 'curation',
    EMAIL = 'email',
    SEGMENTS = 'conditional split',
    FIRST_EMAIL = 'first email',
    PREVIEW = 'preview',
    PUBLISH = 'publish',
    TEST = 'test',
    TIME_DELAY = 'time delay',
    SECOND_EMAIL = 'second email',
    SCHEDULE = 'schedule',
    SMS = 'sms',
}

/**
 * Contact
 */
export enum FilterCategory {
    CONTACT_DETAILS = 'contact details',
    CONTACT_ACTIVITY = 'contact activity',
    CONTACT_EVENTS = 'contact events',
}

export enum RecordType {
    ALL_CONTACTS = 'all contacts',
}

/**
 * Miscellaneous
 */
export enum ApiMethods {
    GET = 'get',
    DELETE = 'delete',
    HEAD = 'head',
    OPTIONS = 'options',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    LINK = 'link',
    UNLINK = 'unlink',
}

export enum BeeConfigType {
    EMAIL = 'email',
    PAGE = 'page',
}

export enum MutationStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
}

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export enum SQLType {
    BOOLEAN = 'boolean',
    CHARACTER_VARYING = 'character varying',
    DATE = 'date',
    DOUBLE_PRECISION = 'double precision',
    INTEGER = 'integer',
    TIMESTAMP_WITH_TIME_ZONE = 'timestamp with time zone',
    CHARACTER_VARYING_ARRAY = 'character varying[]',
}

export enum AllowedMimeTypes {
    TEXT_CSV = 'text/csv',
    TEXT_PLAIN = 'text/plain',
    APPLICATION_PDF = 'application/pdf',
    APPLICATION_ZIP = 'application/zip',
    IMAGE_PNG = 'image/png',
    IMAGE_JPEG = 'image/jpeg',
    APPLICATION_PGP = 'application/pgp-encrypted',
}

/**
 * Constants required to mantain multiple auth providers
 */
export enum AuthConstants {
    OUTREACH_AUTH_PROVIDER = 'outreach.auth.provider',
    OUTREACH_AUTH_USER = 'outreach.auth.user',
    OUTREACH_AUTH_TOKEN = 'outreach.auth.token',
    UAM_TENANT_ID = 'uamTenantId',
    REDIRECT_URL = 'redirectUrl',
    ACCESS_TOKEN = 'accessToken',
    SESSION_EXPIRED = 'session_expired',
    INNOVACCER_DATASHOP_PROVIDER = 'innovaccer:uam',
    AUTH0_PROVIDER = 'innovaccer:auth0',
    USER = 'user',
    INNOVACCER_TENANT_HEADER = 'Innovaccer-Tenant',
}
