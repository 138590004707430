import { useHistory } from 'react-router';
import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import { MutationStatus } from 'models/enums';
import useMutationStatus from 'hooks/useMutationStatus';
import MutationKeys from 'hooks/mutations/keys';
import useContacts from 'hooks/queries/useContacts';
import Button from 'domains/core/components/Button';
import DropdownButton from 'domains/core/components/DropdownButton';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import PageLayout from 'domains/core/components/PageLayout';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ContactsUploadDialog from 'domains/contacts/components/ContactsUploadDialog';
import FileUploadButton from 'domains/contacts/components/FileUploadButton';
import useContactsFileUploadProps from 'domains/contacts/hooks/useContactsFileUploadProps';
import SegmentsTable from '../SegmentsTable';
import { DropdownButtonOption } from 'domains/core/components/DropdownButton/DropdownButton';
import { Props as SegmentTableProps } from '../SegmentsTable/SegmentsTable';
import { Segment } from 'domains/segments/types';
import { Folder } from 'models/types';
import { UseQueryResult } from 'react-query';
import useSegmentQueryManager from 'hooks/useSegmentQueryManager';
import { getSegmentCreatePath } from 'domains/segments/utils';

export type Props = {
    createButtonDropdownOptions?: DropdownButtonOption[];
    isUserOnRootLevel: boolean;
    segmentsQuery: SegmentTableProps<Segment | Folder>['segmentsQuery'] & UseQueryResult<(Segment | Folder)[], Error>;
};

const SegmentsPageLayout = ({ createButtonDropdownOptions, isUserOnRootLevel, segmentsQuery }: Props) => {
    const history = useHistory();
    const { folderId: parentFolderId } = useSegmentQueryManager();

    const {
        data: contacts,
        error: contactsError,
        isError: isContactsError,
        isSuccess: isContactsSuccess,
    } = useContacts({ options: { limit: '1' } });

    const contactsFileUploadProps = useContactsFileUploadProps();

    const createAudienceStatus = useMutationStatus(MutationKeys.CREATE_AUDIENCE);
    const createSegmentStatus = useMutationStatus(MutationKeys.CREATE_SEGMENT);
    const createFolderStatus = useMutationStatus(MutationKeys.CREATE_SEGMENT_FOLDER);

    const { error, isError, isSuccess } = segmentsQuery;

    const isEmpty = isContactsSuccess && !contacts?.length;

    if (isEmpty) {
        return (
            <EmptyStateLayout
                caption="audience"
                header="build your audience"
                guidingText="Once your contacts are uploaded, start segmenting your contacts into audiences to reach the most relevant recipients"
            >
                <ContactsUploadDialog {...contactsFileUploadProps} />
                <FileUploadButton {...contactsFileUploadProps} />
            </EmptyStateLayout>
        );
    }

    const getHeaderAction = () => {
        const icon = <SupervisedUserCircleOutlined />;

        if (!!createButtonDropdownOptions && isUserOnRootLevel) {
            return (
                <DropdownButton
                    ariaLabel="choose what to create"
                    data-testid="audience-create-button-dropdown"
                    icon={icon}
                    label="Create"
                    options={createButtonDropdownOptions}
                    size="medium"
                    variant="outlined"
                />
            );
        }

        return (
            <Button
                data-testid="audience-create-button"
                endIcon={icon}
                onClick={() => history.push(getSegmentCreatePath(parentFolderId))}
                variant="outlined"
            >
                Create
            </Button>
        );
    };

    const renderPageBody = () => <SegmentsTable segmentsQuery={segmentsQuery} isNestedContent={!isUserOnRootLevel} />;

    return (
        <PageLayout
            header="audiences"
            headerIcon={<SupervisedUserCircleOutlined fontSize="inherit" />}
            headerAction={getHeaderAction()}
        >
            {(createAudienceStatus === MutationStatus.SUCCESS || createSegmentStatus === MutationStatus.SUCCESS) && (
                <SuccessSnackbar successMessage="Audience saved successfully." />
            )}
            {createFolderStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Folder created successfully." />
            )}
            {isContactsError && <ErrorSnackbar errorMessage={contactsError?.message} />}
            {isError && <ErrorSnackbar errorMessage={error?.message} />}
            {isSuccess && renderPageBody()}
        </PageLayout>
    );
};

export default SegmentsPageLayout;
