import { InfoOutlined, SmsOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogStandard from 'domains/core/components/DialogStandard';
import TextField from 'domains/core/components/TextField';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import useSendTestSMS from 'hooks/mutations/useSendTestSMS';
import { arePhoneNumbersMobile, arePhoneNumbersValid, formatPhoneNumbers } from './utils';

type Props = {
    isOpen: boolean;
    message: string;
    setIsOpen: (isOpen: boolean) => void;
};

type FormData = {
    phoneNumbers: string;
};

const SMSSendTestDialog = ({ message, isOpen, setIsOpen }: Props) => {
    const [hasInvalidNumbers, setHasInvalidNumbers] = useState(false);
    const [invalidNumbers, setInvalidNumbers] = useState([]);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    const { data, error, isError, isLoading, isSuccess, mutate: sendTestSMS, reset: resetSendTestSMS } = useSendTestSMS(
        {
            onSuccess: (data) => {
                if (data.invalidNumbers.length > 0) {
                    setHasInvalidNumbers(true);
                    setInvalidNumbers(data.invalidNumbers);
                    setIsErrorDialogOpen(true);
                }
            },
            onError: (__error, sendTestSMSInput) => {
                setHasInvalidNumbers(true);
                setInvalidNumbers(sendTestSMSInput.to);
                setIsErrorDialogOpen(true);
            },
        }
    );

    const { control, handleSubmit } = useForm<FormData>({
        mode: 'onTouched',
    });

    useEffect(() => {
        if (isOpen) {
            resetSendTestSMS();
        }
    }, [isOpen, resetSendTestSMS]);

    const onClose = () => setIsOpen(false);

    const onSubmit = (data: FormData) => {
        const to = formatPhoneNumbers(data.phoneNumbers);

        sendTestSMS({ to, message });
        onClose();
    };

    const renderErrorDialog = (wasPartialSuccess: boolean = false) => (
        <DialogStandard
            icon={<SmsOutlined />}
            title={`${wasPartialSuccess ? 'partial' : 'full'} failure`}
            body={
                <>
                    <Typography variant="body1">{`${
                        wasPartialSuccess ? data.errorMessage : error.message
                    }`}</Typography>
                    <Typography variant="body1">{`${invalidNumbers.join(', ')}`}</Typography>
                </>
            }
            buttonOneText="continue"
            buttonOneOnClick={() => {
                onClose();
                setIsErrorDialogOpen(false);
            }}
            buttonTwoText="try again"
            buttonTwoOnClick={() => {
                setIsOpen(true);
                setIsErrorDialogOpen(false);
            }}
            isOpen={isErrorDialogOpen}
            onClose={onClose}
        />
    );

    const renderSuccessIndicator = () => {
        if (hasInvalidNumbers) {
            return renderErrorDialog(true);
        }
        return <SuccessSnackbar successMessage="You've successfully sent a test sms." />;
    };

    return (
        <>
            {isSuccess && renderSuccessIndicator()}
            {isError && renderErrorDialog()}
            <DialogStandard
                actionIcon={<InfoOutlined />}
                actionIconTooltip="Test sends will not include opt-out messaging."
                body={
                    <Box sx={{ pt: 3, pb: 6 }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="phoneNumbers"
                                render={({ field: { onBlur, onChange, value }, fieldState: { error, invalid } }) => {
                                    const helperText = error?.message;
                                    const helperTextMultipleNumbers = 'Use commas to separate multiple phone numbers.';

                                    return (
                                        <TextField
                                            error={invalid}
                                            fullWidth
                                            helperText={invalid ? helperText : helperTextMultipleNumbers}
                                            label="Send to"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                                rules={{
                                    required: 'Please enter recipient phone number(s).',
                                    validate: {
                                        validNumber: (value) =>
                                            arePhoneNumbersValid(formatPhoneNumbers(value)) ||
                                            'Please enter recipient phone number(s) in valid format.',
                                        notMobileNumber: (value) =>
                                            arePhoneNumbersMobile(formatPhoneNumbers(value)) ||
                                            'Please enter mobile phone number(s) for recipients.',
                                    },
                                }}
                            />
                        </form>
                    </Box>
                }
                buttonOneOnClick={onClose}
                buttonOneText="Cancel"
                buttonTwoOnClick={handleSubmit(onSubmit)}
                buttonTwoProps={{ disabled: isLoading }}
                buttonTwoText="Send"
                icon={<SmsOutlined />}
                isOpen={isOpen}
                onClose={onClose}
                title="send test sms"
            />
        </>
    );
};

export default SMSSendTestDialog;
