import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import { CuredApiPaths } from 'models/enums';
import QueryKeys from './keys';

export type Assessment = {
    id: string;
    name: string;
};
export type Assessments = {
    data: Assessment[];
    total: number;
    limit: number;
    offset: number;
};

type UseAssessmentsParams = {
    limit?: number;
    offset?: number;
    searchTerm?: string;
    reactQueryOptions?: UseQueryOptions<Assessments, Error>;
};

const useAssessments = ({ limit = 50, offset = 0, searchTerm, reactQueryOptions }: UseAssessmentsParams = {}) => {
    const params = {
        ...(limit && { limit }),
        ...(offset !== undefined && offset !== null && { offset }),
        ...(searchTerm && { searchTerm }),
    };

    const axiosOptions = {
        ...(Object.keys(params).length && { params }),
    };
    return useQuery<Assessments>(
        QueryKeys.ASSESSMENTS(limit, offset, searchTerm),
        CuredApiPaths.ASSESSMENTS,
        reactQueryOptions,
        axiosOptions
    );
};

export default useAssessments;
