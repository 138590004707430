import { useMemo } from 'react';
import { Box, Chip, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { getNumberWithCommas } from 'utils';
import { Segment } from 'domains/segments/types';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import { CampaignChannelLabels, CampaignChannels } from 'domains/campaigns/types';
import { InfoOutlined } from '@mui/icons-material';
import { RecordType } from 'models/enums';
import { greyChipStyles } from 'domains/core/components/Chip/Chip';
import useAudienceCount, { UseAudienceCountResponse } from 'hooks/queries/useAudienceCount';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { removeTypeFromId } from 'utils/folders';

const chipStyles = { ...greyChipStyles, textTransform: 'capitalize' };

const CampaignChannelTooltips: { [key in CampaignChannels]?: string } = {
    [CampaignChannels.DIRECT_MAIL]: 'This count filters out contacts missing mailing addresses',
    [CampaignChannels.EMAIL]: 'This count filters out unsubscribed and invalid email addresses',
    [CampaignChannels.SMS]: 'This count filters out opted out and invalid phone numbers',
};

type CommonAudienceProps = {
    count: string;
    isError: boolean;
    isLoading: boolean;
    channel?: CampaignChannels;
};

const AudienceCount = ({ count, isError, isLoading, channel }: CommonAudienceProps) => {
    const getDataTestAttribute = (channel?: CampaignChannels, suffix: string = 'segment-count'): string =>
        `${channel ? channel.toLowerCase() + '-' : ''}${suffix}`;

    const channelLabel = CampaignChannelLabels[channel] ? `${CampaignChannelLabels[channel]} ` : '';
    const tooltipTitle = CampaignChannelTooltips[channel] || '';
    const countAsNum = Number(count);
    const countLabelSuffix = countAsNum === 1 ? 'contact' : 'contacts';
    const countLabel = ` ${channelLabel}${countLabelSuffix}`;

    const errorLabel = channelLabel ? `${channelLabel}count not available` : 'Count not available';

    return (
        <Stack direction="row" justifyContent="flex-start">
            {isError && <WarningIcon sx={{ color: 'warning.main', mr: 1 }} />}
            {isError ? (
                <Typography variant="body2" width="100%">
                    {errorLabel}
                </Typography>
            ) : isLoading ? (
                <Typography variant="body2" width="100%">
                    <Skeleton data-testid="segment-count-skeleton" />
                </Typography>
            ) : (
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                    <Typography variant="body2" width="100%">
                        <span data-test={getDataTestAttribute(channel)}>{getNumberWithCommas(countAsNum)}</span>
                        {countLabel}
                    </Typography>
                    {channel && (
                        <Tooltip
                            title={tooltipTitle}
                            sx={{ pointerEvents: 'auto' }}
                            // Setting pointerEvents: 'auto' allows the Tooltip to show on hover if it's used in the label.
                        >
                            <InfoOutlined sx={{ fontSize: 14, mt: 0.5 }} />
                        </Tooltip>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

type Props = {
    segment: Segment;
};

const AudienceDetails = ({ segment }: Props) => {
    const { id: idWithType, segmentDefinition } = segment;
    const id = removeTypeFromId(idWithType);

    const { directMail: hasDirectMail } = useFlags();
    const { data: featureSettings, isSuccess: isFeatureSettingsSuccess } = useFeatureSettings();
    const isSMSEnabled = isFeatureSettingsSuccess && featureSettings?.sms_enabled;

    const { data, isError, isLoading } = useAudienceCount(id, undefined, {
        enabled: !hasInvalidRelativeDates(segment),
    });

    const countData = data as UseAudienceCountResponse;

    const recordTypeChips = useMemo(() => {
        const contactRecordType = segmentDefinition.contactRecordType;

        if (
            !contactRecordType ||
            contactRecordType.length === 0 ||
            contactRecordType.includes(RecordType.ALL_CONTACTS)
        ) {
            return <Chip label={RecordType.ALL_CONTACTS} sx={chipStyles} />;
        }

        return (
            <Stack direction="row" flexWrap="wrap" spacing={1}>
                {contactRecordType.map((recordType, idx) => (
                    <Chip key={recordType + idx} label={recordType} sx={chipStyles} />
                ))}
            </Stack>
        );
    }, [segmentDefinition.contactRecordType]);

    return (
        <>
            <Typography>Audience Details</Typography>
            <AudienceCount
                count={countData?.all}
                isError={!isLoading && (!countData?.all || isError)}
                isLoading={isLoading}
            />
            <AudienceCount
                channel={CampaignChannels.EMAIL}
                count={countData?.email}
                isError={!isLoading && (!countData?.email || isError)}
                isLoading={isLoading}
            />
            {isSMSEnabled && (
                <AudienceCount
                    channel={CampaignChannels.SMS}
                    count={countData?.sms}
                    isError={!isLoading && (!countData?.sms || isError)}
                    isLoading={isLoading}
                />
            )}
            {hasDirectMail && (
                <AudienceCount
                    channel={CampaignChannels.DIRECT_MAIL}
                    count={countData?.directmail}
                    isError={!isLoading && (!countData?.directmail || isError)}
                    isLoading={isLoading}
                />
            )}
            <Box mt={1}>{recordTypeChips}</Box>
        </>
    );
};

export default AudienceDetails;
