import useSegments from 'hooks/queries/useSegments';
import SegmentsPageLayout from '../SegmentsPageLayout';

const SegmentsPageWithoutFolders = () => {
    const segmentsQuery = useSegments();

    return <SegmentsPageLayout isUserOnRootLevel segmentsQuery={segmentsQuery} />;
};

export default SegmentsPageWithoutFolders;
