import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Config, UseConfigResult } from 'models/types';
import configPRPreview from 'configPRPreview';
import configLocalDev from 'configLocalDev';
import configLocalStaging from 'configLocalStaging';
import configLocal from 'configLocal';

const context: UseConfigResult = { loading: true };
export const ConfigContext = createContext(context);

type localEnv = 'dev' | 'staging' | 'local' | undefined;

type Props = {
    children: React.ReactNode;
};

const ConfigProvider = ({ children }: Props) => {
    const [config, setConfig] = useState<Config>();
    const [loading, setLoading] = useState(true);

    const hostname = window.location.hostname;
    const localEnv: localEnv = process.env.REACT_APP_LOCAL_ENV as localEnv;

    useEffect(() => {
        // Local dev environments
        if (hostname === 'localhost') {
            if (localEnv === 'local') {
                setConfig(configLocal);
            } else if (localEnv === 'dev') {
                setConfig(configLocalDev);
            } else {
                setConfig(configLocalStaging);
            }
            setLoading(false);
        }
        // Amplify PR previews
        else if (hostname.includes('.d2xy7h01hb6f2m.amplifyapp.com')) {
            setConfig(configPRPreview);
            setLoading(false);
        }
        // Dev, Staging, Prod
        else {
            const configUrl = `/appsettings.json`;

            (async () => {
                const axiosInstance = axios.create({
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const config = (await axiosInstance(configUrl)).data;
                setConfig(config);
                setLoading(false);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <ConfigContext.Provider value={{ config, loading }}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;
