import { Grading, InfoOutlined } from '@mui/icons-material';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import Button from 'domains/core/components/Button';
import AssessmentDropdown from 'domains/content/components/Bee/AssessmentDialog/AssessmentDropdown';
import { useEffect, useState } from 'react';
import { Assessment } from 'hooks/queries/useAssessments';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import { isTemplate, Template } from 'domains/content/types';
import useCreateTemplate from 'hooks/mutations/useCreateTemplate';
import useUpdateTemplate from 'hooks/mutations/useUpdateTemplate';
import useQueryParams from 'hooks/useQueryParams';
import { Query, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { getContentCreatePath } from 'pages/ContentPage/ContentTable/utils';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import Alert from 'domains/core/components/Alert';
import InputLabel from 'domains/core/components/InputLabel';

export type Props = {
    isDialogVisible: boolean;
    template: Template | object;
    onAssessmentPreview: () => void;
    setBeeEditorIsNoRestart: (isNoRestart: boolean) => void;
    setIsDialogVisible: (isDialogVisible: boolean) => void;
};

const AssessmentDialog = ({
    isDialogVisible,
    template,
    onAssessmentPreview,
    setBeeEditorIsNoRestart,
    setIsDialogVisible,
}: Props) => {
    const [assessmentInputValue, setAssessmentInputValue] = useState('');
    const [assessmentConsent, setAssessmentConsent] = useState<boolean>(true);
    const [initialAssessment, setInitialAssessment] = useState<AutocompleteOption<Assessment>>(null);
    const [initialAssessmentConsent, setInitialAssessmentConsent] = useState<boolean>(true);
    const [selectedAssessment, setSelectedAssessment] = useState<AutocompleteOption<Assessment>>(null);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    useEffect(() => {
        if (isDialogVisible && template) {
            const { assessmentId, assessmentName, assessmentConsentRequired: assessmentConsent } =
                isTemplate(template) && template;
            const assessment =
                assessmentId && assessmentName
                    ? {
                          label: assessmentName,
                          value: { id: assessmentId, name: assessmentName },
                      }
                    : null;
            setSelectedAssessment(assessment);
            setInitialAssessment(assessment);
            const consent = assessmentConsent ?? true;
            setAssessmentConsent(consent);
            setInitialAssessmentConsent(consent);
        }
    }, [template, isDialogVisible]);

    const query = useQueryParams();
    const templateId = query.get('id');
    const parentFolderId = Number(query.get('parentFolderId'));
    const templateBeeJson = isTemplate(template) ? template.beeJson : JSON.stringify(template);
    const queryClient = useQueryClient();
    const history = useHistory();
    const isSaveDisabled = selectedAssessment === initialAssessment && initialAssessmentConsent === assessmentConsent;
    const isAssessmentRemovalAttempt = !selectedAssessment && !!initialAssessment;
    const saveButtonLabel = isAssessmentRemovalAttempt ? 'Save' : 'Save & Copy Tag';

    const onSuccess = async (data: any) => {
        queryClient.invalidateQueries({
            // When we update a template, the cached network calls will be stale for the updated templates, so we need to invalidate the cached GET requests.
            predicate: (query: Query) => query.queryKey[0] === 'template-id',
        });

        if (templateId === 'default') {
            setBeeEditorIsNoRestart(true);
            history.push(getContentCreatePath({ isEmail: true, id: data.id, parentFolderId }));
        }
        if (!isAssessmentRemovalAttempt) {
            await navigator.clipboard.writeText('{{assessment_url}}');
            setShowSuccessSnackbar(true);
        }
    };

    const { mutate: saveTemplate } = useCreateTemplate({ onSuccess });

    const { mutate: updateTemplate } = useUpdateTemplate(templateId, {
        onSuccess,
    });

    useEffect(() => {
        if (showSuccessSnackbar) {
            // Reset success states after a delay
            const timer = setTimeout(() => {
                setShowSuccessSnackbar(false);
            }, 6000); // Snackbar display duration

            return () => clearTimeout(timer);
        }
    }, [showSuccessSnackbar]);

    const onChangeAssessment = (e: any, option: AutocompleteOption<Assessment>) => {
        if (option) {
            setSelectedAssessment(option);
        } else {
            setSelectedAssessment(null);
            setAssessmentConsent(true);
        }
    };

    const handleSubmit = () => {
        const sendDetails = {
            ...(isTemplate(template) && template.preHeader && { preHeader: template.preHeader }),
            ...(isTemplate(template) && template.senderProfileId && { senderProfileId: template.senderProfileId }),
            ...(isTemplate(template) && template.subjectLine && { subjectLine: template.subjectLine }),
        };
        const assessmentInput = {
            assessmentId: selectedAssessment ? selectedAssessment.value.id : '',
            assessmentName: selectedAssessment ? selectedAssessment.value.name : '',
            assessmentConsentRequired: assessmentConsent,
        };
        const name = isTemplate(template) ? template.name : 'Untitled';
        const mutationInput = {
            beeJson: templateBeeJson,
            ...{ templateName: name },
            ...(parentFolderId && { parentFolderId }),
            ...sendDetails,
            ...assessmentInput,
        };

        if (templateId === 'default') {
            saveTemplate(mutationInput);
        } else {
            updateTemplate({ id: templateId, ...mutationInput });
        }

        setIsDialogVisible(false);
    };

    const handleClose = () => {
        setSelectedAssessment(initialAssessment);
        setIsDialogVisible(false);
    };

    return (
        <>
            {showSuccessSnackbar && <SuccessSnackbar successMessage="assessment tag copied!" />}
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isDialogVisible}
                onClose={handleClose}
                TransitionProps={{
                    onExited: () => {},
                }}
            >
                <DialogTitle>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <Grading sx={{ paddingTop: '2px' }} />
                        <Typography variant="h6">select assessment</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ padding: '16px 32px' }}>
                    <DialogContentText mb={3} mt={1}>
                        Select an assessment to include in your email. Copy the merge tag and paste it into a prominent
                        CTA button to encourage completions
                    </DialogContentText>

                    <AssessmentDropdown
                        onAssessmentPreview={onAssessmentPreview}
                        onChange={onChangeAssessment}
                        // For some reason the signature of this prop is wrong and does not match the docs.
                        // @ts-ignore
                        onInputChange={(e: React.SyntheticEvent, inputValue: string, reason: string) => {
                            if (reason === 'clear') {
                                setAssessmentInputValue('');
                                setSelectedAssessment(null);
                            } else {
                                setAssessmentInputValue(inputValue);
                            }
                        }}
                        isPreviewVisible={true}
                        textfieldInput={assessmentInputValue}
                        value={selectedAssessment}
                    />
                    <FormControlLabel
                        control={
                            <Box my={1}>
                                <Switch
                                    color={assessmentConsent ? 'secondary' : 'default'}
                                    size="small"
                                    checked={assessmentConsent}
                                    disabled={!selectedAssessment}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setAssessmentConsent(e.target.checked);
                                    }}
                                    sx={{ mx: 1 }}
                                />
                            </Box>
                        }
                        label={
                            <InputLabel
                                label={
                                    <Typography variant="overline" color={!selectedAssessment && 'text.disabled'}>
                                        Include Consent Form
                                    </Typography>
                                }
                                labelIcon={<InfoOutlined color={'inherit'} fontSize="inherit" />}
                                labelIconTooltipText={
                                    'Collect consent and inform patients of health data usage at the start of your assessment'
                                }
                            />
                        }
                        sx={{ cursor: 'pointer' }}
                    />
                    {isAssessmentRemovalAttempt && (
                        <Alert elevation={0} severity="warning" variant="standard">
                            You are choosing to remove assessment. Please manually remove all assessment tags from the
                            content or URLs once this decision is saved.
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        data-testid="assessment-save-and-copy"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSaveDisabled}
                    >
                        {saveButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default AssessmentDialog;
