import { DateTime } from 'luxon';
import { getTimezone } from 'utils';
import { CampaignChannels } from 'domains/campaigns/types';
import { getIsRecurrenceContinuous, getIsRecurrenceEnabled } from 'domains/campaigns/utils';
import { CampaignSend } from '../../customCampaignReducer';

const getScheduledInfo = (timestamp: string) => {
    if (!timestamp) return;

    const dateTime = DateTime.fromISO(timestamp);
    const month = dateTime.toFormat('LLLL');
    const dayAndYear = dateTime.toFormat('dd, yyyy');
    const time = `${dateTime.toFormat('h:mm a')} ${getTimezone()}`;
    return (
        <span>
            {month} {dayAndYear} at {time}
        </span>
    );
};

export const getValueWithNameAndScheduleInfo = (name: string, send: CampaignSend, shouldShowScheduleInfo: boolean) => {
    const styledName = <em>{name}</em>;
    if (!shouldShowScheduleInfo) return styledName;

    const isRecurrenceEnabled =
        send.channel !== CampaignChannels.DIRECT_MAIL && getIsRecurrenceEnabled(send?.recurrenceFrequency);
    const scheduledSendTime = getScheduledInfo(send?.scheduledTimestamp);

    if (isRecurrenceEnabled) {
        const hasEndDate = !!send?.scheduledEndTimestamp;
        const scheduledSendEndTime = getScheduledInfo(send?.scheduledEndTimestamp);
        const isRecurrenceContinuous: boolean = getIsRecurrenceContinuous(send.recurrenceFrequency);
        const recurrenceFrequency = isRecurrenceContinuous ? 'continually' : send.recurrenceFrequency;
        const recurrenceInfo = (
            <span>
                {styledName} starting {scheduledSendTime} and recurring {recurrenceFrequency}
            </span>
        );

        return hasEndDate ? (
            <span>
                {recurrenceInfo} until {scheduledSendEndTime}
            </span>
        ) : (
            recurrenceInfo
        );
    }

    return (
        <span>
            {styledName} on {scheduledSendTime}
        </span>
    );
};
