import { useParams } from 'react-router';
import { CampaignType } from 'models/enums';
import useCampaignById from 'hooks/queries/useCampaignById';
import { CampaignChannels, CampaignKey } from 'domains/campaigns/types';
import {
    selectIsCampaignRouteDisabled,
    selectIsRouteStartOrPublish,
    selectStateByCampaignKey,
} from 'domains/campaigns/state/selectors';
import CampaignNavBar from 'domains/campaigns/components/CampaignNavBar';
import CampaignDetailsPage from './CampaignDetailsPage';
import ConditionalSplitDetailsPageWrapper from './ConditionalSplit/ConditionalSplitDetailsPageWrapper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setState as setStateEmailSeries } from 'domains/campaigns/state/emailSeries';
import { setState as setStateSingleSend } from 'domains/campaigns/state/singleSend';
import { getCampaignState } from 'domains/campaigns/state/utils';
import CustomCampaignDetailsPage from './CustomCampaignDetailsPage';
import SMSDetailsPage from './SMSDetailsPage';

type NavBarCampaignType = Exclude<CampaignType, CampaignType.CONDITIONAL_SPLIT | CampaignType.FLEXIBLE>;
const NavBarByCampaignType: Record<NavBarCampaignType, React.ReactNode> = {
    [CampaignType.SINGLE_SEND]: (
        <CampaignNavBar
            campaignKey={CampaignKey.SINGLE_SEND}
            readonly
            selectCampaignState={selectStateByCampaignKey(CampaignKey.SINGLE_SEND)}
            selectIsCampaignRouteDisabled={selectIsCampaignRouteDisabled(CampaignKey.SINGLE_SEND)}
            selectIsRouteStartOrPublish={selectIsRouteStartOrPublish(CampaignKey.SINGLE_SEND)}
        />
    ),
    [CampaignType.TIME_DELAY]: (
        <CampaignNavBar
            campaignKey={CampaignKey.EMAIL_SERIES}
            readonly
            selectCampaignState={selectStateByCampaignKey(CampaignKey.EMAIL_SERIES)}
            selectIsCampaignRouteDisabled={selectIsCampaignRouteDisabled(CampaignKey.EMAIL_SERIES)}
            selectIsRouteStartOrPublish={selectIsRouteStartOrPublish(CampaignKey.EMAIL_SERIES)}
        />
    ),
};

const CampaignDetailsPageWrapper = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const { data: campaign, isSuccess: isCampaignSuccess } = useCampaignById(campaignId);
    const dispatch = useDispatch();

    const isSMS = campaign?.channel === CampaignChannels.SMS;

    useEffect(() => {
        if (isCampaignSuccess && !isSMS) {
            const campaignState = getCampaignState({ campaign });

            if (campaign.type === CampaignType.SINGLE_SEND) {
                dispatch(setStateSingleSend(campaignState));
            } else if (campaign.type === CampaignType.TIME_DELAY) {
                dispatch(setStateEmailSeries(campaignState));
            }
        }
    }, [campaign, dispatch, isCampaignSuccess, isSMS]);

    if (!isCampaignSuccess) return;

    if (campaign?.type === CampaignType.FLEXIBLE) {
        return <CustomCampaignDetailsPage campaign={campaign} />;
    }

    if (isSMS) {
        return <SMSDetailsPage campaign={campaign} />;
    }

    if (campaign?.type === CampaignType.CONDITIONAL_SPLIT) {
        return <ConditionalSplitDetailsPageWrapper campaign={campaign} />;
    }

    return <CampaignDetailsPage campaign={campaign} NavBar={NavBarByCampaignType[campaign?.type]} />;
};

export default CampaignDetailsPageWrapper;
