import { useState } from 'react';
import { ContentModalContentType } from 'domains/content/types';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';
import { Segment } from 'domains/segments/types';
import { Folder } from 'models/types';
import useDeleteTableItem from 'hooks/useDeleteTableItem';

type Props = {
    activeItem: ContentModalContentType | Segment | Folder;
    handleModalClose: () => void;
    isOpen: boolean;
    refetchTableData: () => void;
    resetActiveItem: () => void;
};

const DeleteTableItemModal = ({ activeItem, handleModalClose, isOpen, refetchTableData, resetActiveItem }: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const { getDeleteItemFunction, isLoading, isSuccess } = useDeleteTableItem({
        activeItem,
        refetchTableData,
        resetActiveItem,
        setErrorMessage,
        setSuccessMessage,
    });

    const handleDelete = () => {
        const deleteItem = getDeleteItemFunction();

        deleteItem();
        handleModalClose();
    };

    const onCancel = () => {
        handleModalClose();
        resetActiveItem();
    };

    const infoMessage = `Deleting "${activeItem?.name}"`;
    const contentText = `You are about to delete "${activeItem?.name}." This action cannot be undone.`;
    const title = `Delete ${activeItem?.type}`;

    return (
        <>
            {errorMessage && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText,
                    title,
                }}
                onCancel={onCancel}
                onConfirm={handleDelete}
                onModalClose={handleModalClose}
                open={isOpen}
            />
        </>
    );
};

export default DeleteTableItemModal;
