import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, Stack, Typography } from '@mui/material';
import { MailOutline, Wysiwyg, Folder as FolderIcon } from '@mui/icons-material';
import {
    ContentTypeForComparison,
    LandingPage,
    Template,
    ContentModalContentType,
    RootContentFolder,
} from 'domains/content/types';
import DeleteTableItemModal from 'domains/core/components/DeleteTableItemModal';
import FolderBreadcrumb from 'domains/core/components/folders/FolderBreadcrumb';
import FolderEmptyState from 'domains/core/components/folders/FolderEmptyState';
import RenameFolderModal from 'domains/core/components/folders/RenameFolderModal';
import { FolderBreadcrumbData, FolderLineage, FolderModalTypes } from 'domains/core/components/folders/types';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import useGetFolderLineage from 'hooks/queries/useGetFolderLineage';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { Folder, FolderLocation } from 'models/types';
import { getIsFolderContentActive, removeTypeFromId } from 'utils/folders';
import { getContentCreatePath, getContentType } from './utils';
import useGetMoreOptionsCell from './useGetMoreOptionsCell';
import MoveItemModal from 'domains/core/components/folders/MoveItemModal';

export type Props<ContentType> = {
    contentQuery: UseQueryResult<ContentType[], unknown>;
    shouldShowEmptyTable?: Boolean;
};

const ContentTable = <ContentType extends Template | LandingPage | Folder>({
    contentQuery: { data: queryData, isLoading: isQueryLoading, isRefetching, refetch: refetchTableData },
    shouldShowEmptyTable,
}: Props<ContentType>) => {
    const { contentFolder: hasContentFoldersFeature } = useFlags();

    const history = useHistory();

    const { changeViewParams, folderId } = useContentQueryManager();

    const folderContentIsActive = getIsFolderContentActive(folderId);
    const { data: folderBreadcrumbData, refetch: refetchFolderBreadcrumbData } = useGetFolderLineage({
        folderId,
        folderLocation: FolderLocation.CONTENT_FOLDER,
        reactQueryOptions: {
            enabled: folderContentIsActive,
        },
    });
    const folderBreadcrumbDataWithType = folderBreadcrumbData?.map((breadcrumbData: FolderLineage) => ({
        ...breadcrumbData,
        type: ContentTypeForComparison.FOLDER,
    }));

    const [activeContent, setActiveContent] = useState(null);
    const [activeFolder, setActiveFolder] = useState(RootContentFolder);
    const [activeModal, setActiveModal] = useState('');

    useEffect(() => {
        if (folderBreadcrumbData?.length > 0) {
            folderBreadcrumbData.forEach((folder: FolderBreadcrumbData) => {
                if (folder.level === 1) {
                    setActiveFolder({ ...folder, type: ContentTypeForComparison.FOLDER });
                }
            });
        } else {
            setActiveFolder(RootContentFolder);
        }
    }, [folderBreadcrumbData, setActiveFolder]);

    /**
     * If the hasContentFoldersFeature is true, then we prepend the id with the type since
     * we can have a template, landing page, and folder all with the same id.
     */
    const data: object[] = useMemo(
        () =>
            queryData?.map((content: ContentType) => ({
                ...content,
                dateCreated: new Date(content.createdTimestamp),
                dateUpdated: new Date(content.lastModifiedTimestamp),
                id: hasContentFoldersFeature ? `${content.type}:${content.id}` : content.id,
            })),
        [queryData, hasContentFoldersFeature]
    );

    const renderContentIcon = (val: any) => {
        if (val.type === ContentTypeForComparison.EMAIL) return <MailOutline color="info" />;
        if (val.type === ContentTypeForComparison.LANDING_PAGE) return <Wysiwyg />;
        if (val.type === ContentTypeForComparison.FOLDER) return <FolderIcon color="primary" />;
    };

    const handleModalOpen = useCallback(
        (content: ContentModalContentType, modalType: FolderModalTypes, contentType?: ContentTypeForComparison) => {
            const newActiveContent = !!contentType ? { ...content, type: contentType } : content;
            setActiveContent(newActiveContent);
            setActiveModal(modalType);
        },
        []
    );

    const columns: ColumnOption[] = useMemo(() => {
        const renderNameCell = (val: any) => {
            const name = `${val.row.original.isLayout ? 'TEMPLATE - ' : ''}${val.cell.value}`;
            if (hasContentFoldersFeature) {
                return (
                    <Stack alignItems="center" direction="row" spacing={2} color="warning.dark">
                        {renderContentIcon(val.row.original)}
                        <Typography>{name}</Typography>
                    </Stack>
                );
            }
            const type = getContentType(val.row.original, hasContentFoldersFeature);
            const isTemplate = type === ContentTypeForComparison.EMAIL;
            return <Link href={getContentCreatePath({ isEmail: isTemplate, id: val.row.original.id })}>{name}</Link>;
        };

        const nameColumn: ColumnOption = {
            Header: 'Name',
            accessor: 'name',
            sortType: 'string',
            align: 'left',
            Cell: (val: any) => renderNameCell(val),
        };
        const dateCreatedColumn: ColumnOption = {
            Header: 'Date Created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const dateUpdatedColumn: ColumnOption = {
            Header: 'Date Updated',
            accessor: 'dateUpdated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const typeColumn: ColumnOption = {
            Header: 'Type',
            accessor: 'type',
            Cell: (val: any) => {
                const type = getContentType(val.row.original, hasContentFoldersFeature);
                return type.toLowerCase();
            },
        };
        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'id',
            align: 'right',
            disableSortBy: true,
            Cell: (val: any) => {
                const type = getContentType(val.row.original, hasContentFoldersFeature);
                const isTemplate = type === ContentTypeForComparison.EMAIL;
                return useGetMoreOptionsCell({ isTemplate, handleModalOpen, val });
            },
        };

        if (hasContentFoldersFeature) {
            return [nameColumn, typeColumn, dateCreatedColumn, dateUpdatedColumn, moreOptionsColumn];
        }

        return [nameColumn, dateCreatedColumn, dateUpdatedColumn, typeColumn, moreOptionsColumn];
    }, [handleModalOpen, hasContentFoldersFeature]);

    // TODO: update to use real row type here
    const onRowClick = (row: any) => {
        const id = removeTypeFromId(row.original.id);
        if (row.original.type === ContentTypeForComparison.FOLDER) {
            changeViewParams({ newFolderId: id });
        } else {
            const path = getContentCreatePath({
                isEmail: row.original.type === ContentTypeForComparison.EMAIL,
                id,
                isCopy: false,
                parentFolderId: folderId,
            });
            history.push(path);
        }
    };

    const handleBreadcrumbClick = (newFolderId: number) => changeViewParams({ newFolderId });

    return (
        <>
            <DeleteTableItemModal
                activeItem={activeContent}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === FolderModalTypes.DELETE}
                refetchTableData={refetchTableData}
                resetActiveItem={() => setActiveContent(null)}
            />
            <RenameFolderModal
                activeFolder={activeContent}
                folderLocation={FolderLocation.CONTENT_FOLDER}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === FolderModalTypes.RENAME}
                refetch={folderContentIsActive ? refetchFolderBreadcrumbData : refetchTableData}
                resetActiveFolder={() => setActiveContent(null)}
            />
            <MoveItemModal
                activeItem={activeContent}
                activeFolder={activeFolder}
                folderLocation={FolderLocation.CONTENT_FOLDER}
                handleModalClose={() => setActiveModal('')}
                isOpen={activeModal === FolderModalTypes.MOVE}
                refetch={refetchTableData}
                resetActiveItem={() => setActiveContent(null)}
            />
            {hasContentFoldersFeature && (
                <FolderBreadcrumb
                    data={folderBreadcrumbDataWithType}
                    handleBreadcrumbClick={handleBreadcrumbClick}
                    handleModalOpen={handleModalOpen}
                    showDeleteFolderOption={shouldShowEmptyTable}
                />
            )}
            {!isQueryLoading && (
                <Table
                    data={data}
                    columns={columns}
                    defaultSortingRules={[{ id: 'dateUpdated', desc: true }]}
                    isRefetching={isRefetching}
                    {...(hasContentFoldersFeature && { onRowClick })}
                    refetch={refetchTableData}
                />
            )}
            {shouldShowEmptyTable && <FolderEmptyState location={FolderLocation.CONTENT_FOLDER} />}
        </>
    );
};

export default ContentTable;
