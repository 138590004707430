import { Grading } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Stack,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Button from 'domains/core/components/Button';
import InputLabel from 'domains/core/components/InputLabel';
import TextField from 'domains/core/components/TextField';
import useAssessmentConsent from 'hooks/queries/useAssessmentConsent';

type AssessmentPreviewDialogProps = {
    handleClose: () => void;
    isOpen: boolean;
};

const frameStyle = {
    bgcolor: 'background.paper',
    my: 2,
    border: 12,
    borderColor: 'grey.200',
    borderRadius: '6px',
    p: 1,
};

const AssessmentPreviewDialog = ({ isOpen, handleClose }: AssessmentPreviewDialogProps) => {
    const { data: assessmentConsent, isSuccess: isAssessmentConsentSuccess } = useAssessmentConsent();
    const consentVerificationType = assessmentConsent?.verificationType === 'YEAR' ? 'year' : 'date';
    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isOpen && isAssessmentConsentSuccess}
                onClose={handleClose}
                TransitionProps={{
                    onExited: () => {},
                }}
            >
                <DialogTitle>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <Grading sx={{ paddingTop: '2px' }} />
                        <Typography variant="h6">preview</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ padding: '16px 32px' }}>
                    <Box sx={frameStyle}>
                        <Stack>
                            <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                                Consent Form
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '13px', mt: 1 }}>
                                {assessmentConsent?.message}
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '13px', fontWeight: 'bold', mt: 2 }}>
                                {`To verify it is you, please enter your ${consentVerificationType} of birth`}
                            </Typography>
                            {consentVerificationType === 'date' && (
                                <DatePicker
                                    disabled={true}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            sx: { border: 0, height: '30px', width: '185px', mt: 1 },
                                            size: 'small',
                                            placeholder: 'MM / DD / YYYY',
                                        },
                                    }}
                                />
                            )}
                            {consentVerificationType === 'year' && (
                                <TextField disabled={true} sx={{ width: '100px', mt: 1 }} size="small" />
                            )}
                            <FormControlLabel
                                control={
                                    <Box my={1}>
                                        <Checkbox color="default" size="small" disabled={true} />
                                    </Box>
                                }
                                label={
                                    <InputLabel
                                        label={
                                            <Typography variant="overline">
                                                I give consent to take this assessment.
                                            </Typography>
                                        }
                                    />
                                }
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={true}
                                size="small"
                                sx={{ width: '185px' }}
                            >
                                Take Assessment
                            </Button>
                        </Stack>
                    </Box>
                    <DialogContentText sx={{ fontSize: '16px' }} mb={3} mt={1}>
                        Above is a preview of the consent form that will appear at the start of the assessment. You can
                        preview the assessment in Care Management.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AssessmentPreviewDialog;
